import { Component } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { lastValueFrom } from 'rxjs';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-pdf-report',
  templateUrl: './pdf-report.component.html',
  styleUrls: ['./pdf-report.component.scss'],
})
export class PdfReportComponent {
  constructor(private backendService: BackendService) { }
  config = {
    options: {
      googleAccessToken: 'AIzaSyBlFjVhwBrg342hLHvaX1GgLYbg6wt5HsU'
    }
  }

  getStaticMapImageUrl = (
    latitude: number,
    longitude: number,
    width: number,
    height: number,
    accessToken: string,
    feature: any,
    address:any
  ) => {

    const baseUrl = 'https://api.mapbox.com/styles/v1/zlvas/cm0vhtj21001801nq3atr5gjp/static';
    const baseUrl2 = 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static';
    const baseUrl3 = 'https://api.mapbox.com/styles/v1/zlvas/cm0wtj7ow00f001nt8f1j7rej/static';//style3d
    const baseUrl4 = 'https://api.mapbox.com/styles/v1/zlvas/cm0r52xws02r001qqb2si6qzj/static'; //report1 style
    const baseUrl5 = 'https://api.mapbox.com/styles/v1/zlvas/cm0xxh9m5003w01qlfpimcm9s/static'; //report4 style
    const baseUrl6 = 'https://api.mapbox.com/styles/v1/zlvas/cm0y3n0hj00ff01p43g8tgjeo/static'; //report5 style
    const baseUrl7 = 'https://maps.googleapis.com/maps/api/streetview?size=640x316&location='; //streetview

    const coordinates = `${longitude},${latitude}`;
    const getImg1 = `geojson(${JSON.stringify(feature.lot)})`; // Cerrar paréntesis aquí  zoom/pitch
    const getImg2d = `geojson(${JSON.stringify(feature.lot2d)})`;
    const getimg3d = `${JSON.stringify(feature.build)}`
    const url2d = `${baseUrl}/${encodeURIComponent(getImg2d)}/[${feature.bbox}]/${width}x${height}@2x?access_token=${accessToken}&before_layer=subway-stations-TEXT`; //aqui pondremos el BBbox !geojson+bbbox!
    const url = `${baseUrl}/${encodeURIComponent(getImg1)}/[${feature.bbox}]/${width}x${height}@2x?access_token=${accessToken}`; //aqui pondremos el BBbox !geojson+bbbox!
    const url2 = `${baseUrl2}/${encodeURIComponent(getImg1)}/${coordinates},18.5,0/${width}x${height}@2x?access_token=${accessToken}`;
    const url3 = `${baseUrl2}/${encodeURIComponent(getImg1)}/${coordinates},17,0/923x1280@2x?access_token=${accessToken}`;
    const url3d = `${baseUrl3}/${encodeURIComponent(getImg1)}/${coordinates},16.5,345,40/${width}x${height}@2x?addlayer=${encodeURIComponent(getimg3d)}&access_token=${accessToken}`; //3d
    const urlAppendix = `${baseUrl4}/${encodeURIComponent(getImg1)}/${coordinates},16,0/923x1280@2x?access_token=${accessToken}`; //todo her pablo
    const urlAppendix2 = `${baseUrl5}/${encodeURIComponent(getImg1)}/${coordinates},16,0/923x1280@2x?access_token=${accessToken}`;
    const urlAppendix3 = `${baseUrl6}/${encodeURIComponent(getImg1)}/${coordinates},16,0/923x1280@2x?access_token=${accessToken}`;
    const streetview = `${baseUrl7}${encodeURIComponent(address)}&source=outdoor&fov=120&pitch=12&key=${this.config.options.googleAccessToken}`;

    return [url, url2, url3, url2d, url3d, urlAppendix, urlAppendix2, urlAppendix3, streetview];
  };

  // Método para convertir una URL de imagen a base64
  fetchImageAsBase64 = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  generatePDFWithMapImage = async (bbl: any, access_token: any, features: any, latitude: any, longitude: any, address:any): Promise<any[]> => {
    try {  
      // Obtener URLs de las imágenes
      const imageUrls = this.getStaticMapImageUrl(latitude, longitude, 1280, 633, access_token, features,address);
      
      // Descargar las imágenes en paralelo
      const imagePromises = imageUrls.map(url => this.fetchImageAsBase64(url));
      
      // Esperar a que todas las promesas se resuelvan
      const base64Images = await Promise.all(imagePromises);
      
      return base64Images;

    } catch (error) {
      console.error('Error en generatePDFWithMapImage:', error);
      throw error; // Lanza el error para que pueda ser manejado por quien llame a esta función
    }
  };

  async generateDataPDF(bbl: any, access_token: any, features: any, pdfName:any) {
    console.log('generate pdf bbl', bbl);

    const dataFromBBL = await lastValueFrom(this.backendService.getDataFromBBL(parseInt(bbl),'PDF'));
    const dataFromRegulations = await lastValueFrom(this.backendService.getDataFromRegulations(parseInt(bbl)));

    const latitude = dataFromBBL.latitude;
    const longitude = dataFromBBL.longitude;
    const address = dataFromBBL.address

    const [report1, report2, report3, report2d, report3d, appendix, appendix2, appendix3, streetview] = await this.generatePDFWithMapImage(bbl, access_token, features , latitude, longitude,address);

    const data_bbl: any = {
      address: `${dataFromBBL.address}`,
      borough: `${dataFromBBL.borough}`,
      bbl: bbl,
      block: `${dataFromBBL.block}`,
      lot: `${dataFromBBL.lot}`,
      cd: `${dataFromBBL.cd}`,
      zonemap: `${dataFromBBL.zonemap}`,
      lot_img: '/assets/images/pdf/Slide1.png',
      lot_map: streetview,
      external_links: {
        zoning_map: `https://s-media.nyc.gov/agencies/dcp/assets/files/pdf/zoning/zoning-maps/map${dataFromBBL.zonemap}.pdf`,
        building_info: `https://a810-bisweb.nyc.gov/bisweb/PropertyBrowseByBBLServlet?allborough=${dataFromBBL.boroughn}&allblock=${dataFromBBL.block}&alllot=${dataFromBBL.lot}&go5=+GO+&requestid=0`,
        digital_tax_map: `https://propertyinformationportal.nyc.gov/parcels/parcel/${bbl}`,
        property_records: `http://a836-acris.nyc.gov/bblsearch/bblsearch.asp?borough=${dataFromBBL.boroughn}&block=${dataFromBBL.block}&lot=${dataFromBBL.lot}`,
      },
      owner_data: {
        ownername: `${dataFromBBL.ownername}`,
        zd_ownertype: `${dataFromBBL.zd_ownertype}`,
      },
      geometry: {
        lotarea: `${parseFloat(dataFromBBL.lotarea).toLocaleString('en-US')}`,
        lotfront: `${parseFloat(dataFromBBL.lotfront).toLocaleString('en-US')}`,
        lotdepth: `${parseFloat(dataFromBBL.lotdepth).toLocaleString('en-US')}`,
        irrlotcode: `${dataFromBBL.irrlotcode}`,
        zd_lottype: `${dataFromBBL.zd_lottype}`, //aqui hay una incongruencia
      },
      zoning_layers: {
        zonedist: [
          `${dataFromBBL.zonedist1}`,
          `${dataFromBBL.zonedist2}`,
          `${dataFromBBL.zonedist3}`,
          `${dataFromBBL.zonedist4}`,
        ] /*equivalent*/,
        overlay: [`${dataFromBBL.overlay1}`, `${dataFromBBL.overlay2}`],
        spdist: [`${dataFromBBL.spdist1}`, `${dataFromBBL.spdist2}`, `${dataFromBBL.spdist3}`],
        limitedheightdistrict: [`${dataFromBBL.ltdheight}`], // lo mismo
        splitdistrictlot: `${dataFromBBL.splitzone}`, // lo mismo
      },
      additional_layers: {
        flood_zone: `${dataFromBBL.flood_zone}`,
        histdist: `${dataFromBBL.histdist}`,
        zd_landmark: `${dataFromBBL.zd_landmark}`,
        landurl: `${dataFromBBL.landurl}`,
        edesignum: {
          ed: `${dataFromBBL.edesignum}`,
          nycoer:
            'https://www.nyc.gov/site/oer/remediation/e-designation.page',
            'e-408':
            'https://zr.planning.nyc.gov/appendix-c-table-1-city-environmental-quality-review-ceqr-environmental-requirements-e-designations',
        },
      }, //varias cosas que no son de aqui
      building: {
        zd_landuse: `${dataFromBBL.zd_landuse}`,
        zd_bldgclass: `${dataFromBBL.zd_bldgclass}`,
        yearbuilt: `${dataFromBBL.yearbuilt}`,
        year_altered: `${dataFromBBL.year_altered}`,
        numbldgs: `${dataFromBBL.numbldgs}`,
        numfloors: `${dataFromBBL.numfloors}`,
        zd_bsmtcode: `${dataFromBBL.zd_bsmtcode}`,
        unitsres: `${dataFromBBL.unitsres}`,
        unitstotal: `${dataFromBBL.unitstotal}`,
      },
      floor_area_distribution: {
        bldgarea: `${parseFloat(dataFromBBL.bldgarea).toLocaleString('en-US')}`,
        resarea: `${parseFloat(dataFromBBL.resarea).toLocaleString('en-US')}`,
        comarea: `${parseFloat(dataFromBBL.comarea).toLocaleString('en-US')}`,
        officearea: `${parseFloat(dataFromBBL.officearea).toLocaleString('en-US')}`,
        retailarea: `${parseFloat(dataFromBBL.retailarea).toLocaleString('en-US')}`,
        garagearea: `${parseFloat(dataFromBBL.garagearea).toLocaleString('en-US')}`,
        strgearea: `${parseFloat(dataFromBBL.strgearea).toLocaleString('en-US')}`,
        factryarea: `${parseFloat(dataFromBBL.factryarea).toLocaleString('en-US')}`,
        otherarea: `${parseFloat(dataFromBBL.otherarea).toLocaleString('en-US')}`,
      },
      graph: '/assets/images/pdf/image.png',
      bulk: '/assets/images/pdf/bulk.png',
      image_3d: '/assets/images/pdf/Slide3.png',
      financial: [
        {
          year: '2020',
          taxc2020: `${parseFloat(dataFromBBL.taxc2020).toLocaleString('en-US')}`,
          prop2020: `${parseFloat(dataFromBBL.prop2020).toLocaleString('en-US')}`,
          land2020: `${parseFloat(dataFromBBL.land2020).toLocaleString('en-US')}`,
        },
        {
          year: '2021',
          taxc2021: `${parseFloat(dataFromBBL.taxc2021).toLocaleString('en-US')}`,
          prop2021: `${parseFloat(dataFromBBL.prop2021).toLocaleString('en-US')}`,
          land2021: `${parseFloat(dataFromBBL.land2021).toLocaleString('en-US')}`,
        },
        {
          year: '2022',
          taxc2022: `${parseFloat(dataFromBBL.taxc2022).toLocaleString('en-US')}`,
          prop2022: `${parseFloat(dataFromBBL.prop2022).toLocaleString('en-US')}`,
          land2022: `${parseFloat(dataFromBBL.land2022).toLocaleString('en-US')}`,
        },
        {
          year: '2023',
          taxc2023: `${parseFloat(dataFromBBL.taxc2023).toLocaleString('en-US')}`,
          prop2023: `${parseFloat(dataFromBBL.prop2023).toLocaleString('en-US')}`,
          land2023: `${parseFloat(dataFromBBL.land2023).toLocaleString('en-US')}`,
        },
        {
          year: '2024',
          taxc2024: `${parseFloat(dataFromBBL.taxc2024).toLocaleString('en-US')}`,
          prop2024: `${parseFloat(dataFromBBL.prop2024).toLocaleString('en-US')}`,
          land2024: `${parseFloat(dataFromBBL.land2024).toLocaleString('en-US')}`,
        },
      ],
      financial_graph: '/assets/images/pdf/financial_graph.png',
      floor_area_data: {
        FAR: {
          BLDGAREA: dataFromRegulations.message.FAR.BLDGAREA,
          A_TOTAL: dataFromRegulations.message.FAR.A_TOTAL,
          SPLITZONE: dataFromRegulations.message.FAR.SPLITZONE,
          DIST: dataFromRegulations.message.FAR.DIST,
          ADJUSTED: dataFromRegulations.message.FAR.ADJUSTED,
          MAX: dataFromRegulations.message.FAR.MAX,
          MAX_IH: dataFromRegulations.message.FAR.MAX_IH,
          EXISTING: dataFromRegulations.message.FAR.EXISTING,
          REMAIN: dataFromRegulations.message.FAR.REMAIN,
        },
      },
      zoning_analysis: {
        img: '/assets/images/pdf/Slide4.png',
        use: {
          zr_section: [
            {
              zr: 'ZR 81-621',
              url: 'https://zr.planning.nyc.gov/article-viii/chapter-1#81-621',
            },
          ],
          district: ['C5-3', 'MiD'],
          allowed: [
            'Residential Use group 1, 2',
            'Community Facility Use Group 3, 4',
            'Commercial Use Group 5, 6, 9, 10, 11',
          ],
          existing: ['Community Facility 3', 'Commercial 6, 9'],
        },
      },
    };

    await this.generatePDF(data_bbl, bbl, access_token, features, pdfName,[report1, report2, report3, report2d, report3d, appendix, appendix2, appendix3, streetview]);
  
    return true
  }

  generatePDF = async (data_bbl: any, bbl: any, access_token: any, features: any, pdfName:any, apImage:any) => {
    const doc = new jsPDF('p', 'mm', [215.9, 279.4]);
    // doc.addFileToVFS('GothamLight-normal')
    // doc.setFont('GothamLight-normal');
    const [report1, report2, report3, report2d, report3d, appendix, appendix2, appendix3, streetview] = apImage
    // console.log('reportImage', image1)
    doc.setFont('helvetica');

    const addFooters = (doc: any) => {
      const pageCount = doc.internal.getNumberOfPages();
      const date = new Date().toLocaleDateString('en-US');

      // doc.setFont('helvetica', 'italic')
      doc.setFontSize(9);
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text(`${date}`, 26, 270);
        doc.text('Page ' + String(i) + ' of ' + String(pageCount), 170.9, 270);
      }
    };

    const addHeader = (doc: any) => {
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(18);
      doc.text(`${data_bbl.address}`, 26, 14);
      doc.setFontSize(24);
      doc.text(`zlvas`, 170.3, 14);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
    };

    function textWithUnderline(doc: jsPDF, text: string, x: number, y: number) {
      doc.text(text, x, y);

      const textWidth = doc.getTextWidth(text);

      // Posicionar la línea subrayada justo debajo del texto
      const underlineY = y + 0.5; // Ajustar este valor según sea necesario

      // Dibujar la línea subrayada
      doc.setLineWidth(0.1);
      doc.line(x, underlineY, x + textWidth, underlineY);
    }
    addHeader(doc);
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(18);
    doc.setTextColor(233, 189, 106)
    doc.text(`Zoning Report`, 26, 22);
    doc.setTextColor(0, 0, 0)

    doc.setFontSize(10);

    doc.addImage(report3, 'PNG', 26, 26, 163.9, 227.4, 'report-satelital', 'SLOW');
    doc.addImage('/assets/images/pdf/north_symbol.png', 'PNG', 180.41, 242.4, 8, 8, 'north', 'SLOW');


    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(`Figure 1: Satellite view`, 26, 257.4);
    doc.setFontSize(10);
    //page2
    doc.addPage();
    doc.setFont('helvetica', 'normal');


    addHeader(doc);
    // Subtítulo
    doc.setFont('helvetica', 'normal');

    doc.setFontSize(10);

    doc.text(
      `${data_bbl.borough} | Block ${data_bbl.block} | Lot ${data_bbl.lot}`,
      26,
      27
    );
    /*18*/
    doc.text(`BBL ${data_bbl.bbl}`, 26, 33);
    const zonedistFiltered = data_bbl.zoning_layers.zonedist.filter(
      (item: any) => item !== '-'
    );
    const overlayFiltered = data_bbl.zoning_layers.overlay.filter(
      (item: any) => item !== '-'
    );
    const overlayText =
      overlayFiltered.length > 0 ? overlayFiltered.join(', ') : '-';
    const spdistFiltered = data_bbl.zoning_layers.spdist.filter(
      (item: any) => item !== '-'
    );
    const spdistText =
      spdistFiltered.length > 0 ? spdistFiltered.join(', ') : '-';
    const zonedistText =
      zonedistFiltered.length > 0 ? zonedistFiltered.join(', ') : '-';
    const ltdheightFiltered =
      data_bbl.zoning_layers.limitedheightdistrict.filter(
        (item: any) => item !== '-'
      );
    const ltdheightText =
      ltdheightFiltered.length > 0 ? ltdheightFiltered.join(', ') : '-';

    let titleZonedist =
      (zonedistText !== '-' ? zonedistText : '') +
      (overlayText !== '-'
        ? (zonedistText !== '-' ? ', ' : '') + overlayText
        : '') +
      (spdistText !== '-'
        ? (zonedistText !== '-' || overlayText !== '-' ? ', ' : '') + spdistText
        : '') +
      (ltdheightText !== '-'
        ? (zonedistText !== '-' || overlayText !== '-' || spdistText !== '-'
          ? ', '
          : '') + ltdheightText
        : '');

    doc.text(`Zoning District: ${titleZonedist}`, 26, 40); //corregir

    // Enlaces externos
    doc.text('External Links:', 26, 46);
    doc.setTextColor(0, 0, 255);
    doc.textWithLink('Building Info', 50, 46, {
      url: `${data_bbl.external_links.building_info}`,
    });
    doc.setTextColor(0, 0, 0);
    doc.text(`|`, 70, 46);
    doc.setTextColor(0, 0, 255);
    doc.textWithLink('Digital Tax Map', 72, 46, {
      url: `${data_bbl.external_links.digital_tax_map}`,
    });
    doc.setTextColor(0, 0, 0);
    doc.text(`|`, 97, 46);
    doc.setTextColor(0, 0, 255);
    doc.textWithLink('Property Records', 99, 46, {
      url: `${data_bbl.external_links.property_records}`,
    });
    doc.setTextColor(0, 0, 0);
    doc.text(`|`, 127, 46);
    doc.setTextColor(0, 0, 255);
    doc.textWithLink(`Zoning Map ${data_bbl.zonemap}`, 129, 46, {
      url: `${data_bbl.external_links.zoning_map}`,
    });
    doc.setTextColor(0, 0, 0);

    // Imagen del mapa
    doc.addImage(report3d, 'PNG', 26, 52, 163.9, 81, 'imglot', 'SLOW');

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(`Figure 2: Site 3D view`, 26, 137);
    doc.setFontSize(10);
    // Tabla de datos
    const tableData = [
      {
        header: 'Owner Data',
        data: [
          ['Owner Name', `${data_bbl.owner_data.ownername}`],
          ['Type of Ownership', `${data_bbl.owner_data.zd_ownertype}`],
        ],
      },
      {
        header: 'Property Data',
        data: [
          ['Address', `${data_bbl.address}`],
          ['Borough', `${data_bbl.borough}`],
          ['Block', `${data_bbl.block}`],
          ['Lot', `${data_bbl.lot}`],
          ['Community District', `${data_bbl.cd}`],
        ],
      },
      {
        header: 'Geometry',
        data: [
          ['Lot Area', `${data_bbl.geometry.lotarea} sf`],
          ['Lot Frontage', `${data_bbl.geometry.lotfront} ft`],
          ['Lot Depth', `${data_bbl.geometry.lotdepth} ft`],
          ['Irregular Lot', `${data_bbl.geometry.irrlotcode}`],
          ['Lot Type', `${data_bbl.geometry.zd_lottype}`], //todo here changes we need
        ],
      },
      {
        header: 'Zoning Data',
        data: [
          ['Zoning District', `${zonedistFiltered.join(', ')}`],
          ['Overlay District', `${overlayText}`],
          ['Special District', `${spdistText}`],
          [
            'Limited Height District',
            `${data_bbl.zoning_layers.limitedheightdistrict[0]}`,
          ],
          ['Split District Lot', `${data_bbl.zoning_layers.splitdistrictlot}`],
        ],
      },
      {
        header: 'Additional layers',
        data: [
          ['', ``],
          ['', `${data_bbl.additional_layers.histdist}`],
          ['', ''],
          ['', ``],
        ],
      },
    ];
    // doc.text('Lot:',  26, 139);
    doc.setFont('helvetica', 'bold');
    textWithUnderline(doc, 'Lot information', 26, 143);
    // doc.setFontSize(110);
    doc.setFont('helvetica', 'normal');

    // const startX = 40;
    let startY = 147;
    // const rowHeight = 10;
    // const colWidth = 150;

    tableData.forEach((section) => {
      autoTable(doc, {
        styles: {
          fontSize: 7,
          lineWidth: 0.2,
          lineColor: 'black',
          cellPadding: 0.6,
        },
        headStyles: {
          fillColor: [242, 242, 242],
          lineWidth: 0.2,
        },
        columnStyles: {
          0: { cellWidth: 53 },
          1: { cellWidth: 110.9 },
        },
        startY, // Posición en el eje Y (vertical)
        margin: {
          left: 26,
        },
        tableWidth: 166,
        // Posición en el eje X (horizontal)
        head: [
          [
            {
              content: section.header,
              colSpan: 2,
            },
          ],
        ],
        body: section.data,
        theme: 'plain',
        didDrawCell: (data) => {
          // Verifica si la celda es la de 'Environmental Designation'
          if (
            section.header === 'Additional layers' &&
            data.row.index === 3 &&
            data.column.index === 1
          ) {
            // Ajusta el enlace y el texto según sea necesario
            const link =
              'https://zr.planning.nyc.gov/appendix-c-table-1-city-environmental-quality-review-ceqr-environmental-requirements-e-designations';
            const cellText = `${data_bbl.additional_layers.edesignum.ed}`;
            if (cellText !== 'n/a') {
              // Coloca el enlace sobre el texto de la celda
              doc.setTextColor(0, 0, 255); // Color del enlace

              doc.textWithLink(
                cellText,
                data.cell.x + 0.7, // Ajusta las coordenadas para centrar el texto en la celda
                data.cell.y + 2.7, // Ajuste vertical para centrar
                { url: link }
              );
            } else {
              doc.setTextColor(0, 0, 0);
              doc.text(cellText, data.cell.x + 0.7, data.cell.y + 2.7);
            }
          }

          if (
            section.header === 'Additional layers' &&
            data.row.index === 3 &&
            data.column.index === 0
          ) {
            // Ajusta el enlace y el texto según sea necesario
            const link = `https://www.nyc.gov/site/oer/remediation/e-designation.page`; // Supongo que tienes una URL en tu objeto de datos
            const cellText = `Environmental Designation`;
            doc.setTextColor(0, 0, 255); // Color del enlace
            // Coloca el enlace sobre el texto de la celda
            doc.textWithLink(
              cellText,
              data.cell.x + 0.7, // Ajusta las coordenadas para centrar el texto en la celda
              data.cell.y + 2.7, // Ajuste vertical para centrar
              { url: link }
            );
          }
          if (
            section.header === 'Additional layers' &&
            data.row.index === 0 &&
            data.column.index === 1
          ) {
            // Ajusta el enlace y el texto según sea necesario
            const link =
              'https://zr.planning.nyc.gov/article-vi/chapter-4';
            const cellText2 = `${data_bbl.additional_layers.flood_zone}`;
            const excludedValues = ['n/a', 'No', '-'];
            if (!excludedValues.includes(cellText2)) {
              // Coloca el enlace sobre el texto de la celda
              doc.setTextColor(0, 0, 255); // Color del enlace

              doc.textWithLink(
                cellText2 + ' (See ZR 64-00 for Special Regulations Applying in Flood Zones)',
                data.cell.x + 0.7, // Ajusta las coordenadas para centrar el texto en la celda
                data.cell.y + 2.7, // Ajuste vertical para centrar
                { url: link }
              );
            } else {
              doc.setTextColor(0, 0, 0);
              doc.text(cellText2, data.cell.x + 0.7, data.cell.y + 2.7);
            }
          }
          if (
            section.header === 'Additional layers' &&
            data.row.index === 0 &&
            data.row.section === 'body' &&
            data.column.index === 0
          ) {
            const link =
              'https://dcp.maps.arcgis.com/apps/webappviewer/index.html?id=1c37d271fba14163bbb520517153d6d5/';
            const cellText4 = `Flood Zone`;
            doc.setTextColor(0, 0, 255); // Color del enlace

            doc.textWithLink(
              cellText4,
              data.cell.x + 0.7, // Ajusta las coordenadas para centrar el texto en la celda
              data.cell.y + 2.7, // Ajuste vertical para centrar
              { url: link }
            )
          }
          if (
            section.header === 'Additional layers' &&
            data.row.index === 1 &&
            data.row.section === 'body' &&
            data.column.index === 0
          ) {
            const link =
              'https://nyclpc.maps.arcgis.com/apps/webappviewer/index.html?id=93a88691cace4067828b1eede432022b';
            const cellText4 = `Historic District`;
            doc.setTextColor(0, 0, 255); // Color del enlace

            doc.textWithLink(
              cellText4,
              data.cell.x + 0.7, // Ajusta las coordenadas para centrar el texto en la celda
              data.cell.y + 2.7, // Ajuste vertical para centrar
              { url: link }
            )
          }
          if (
            section.header === 'Additional layers' &&
            data.row.index === 2 &&
            data.row.section === 'body' &&
            data.column.index === 0
          ) {
            const link =
              'https://nyclpc.maps.arcgis.com/apps/webappviewer/index.html?id=93a88691cace4067828b1eede432022b';
            const cellText4 = `Landmark`;
            doc.setTextColor(0, 0, 255); // Color del enlace

            doc.textWithLink(
              cellText4,
              data.cell.x + 0.7, // Ajusta las coordenadas para centrar el texto en la celda
              data.cell.y + 2.7, // Ajuste vertical para centrar
              { url: link }
            )
          }
          if (
            section.header === 'Additional layers' &&
            data.row.index === 2 &&
            data.column.index === 1
          ) {
            const cellText3 = `${data_bbl.additional_layers.zd_landmark}`;
            const excludedValues = ['n/a', 'No', '-'];
            if (!excludedValues.includes(cellText3)) {
              doc.setTextColor(0, 0, 255); // Color del enlace

              doc.textWithLink(
                cellText3,
                data.cell.x + 0.7, // Ajusta las coordenadas para centrar el texto en la celda
                data.cell.y + 2.7, // Ajuste vertical para centrar
                { url: `${data_bbl.additional_layers.landurl}` }
              );
            } else {
              doc.setTextColor(0, 0, 0);
              doc.text(cellText3, data.cell.x + 0.7, data.cell.y + 2.7);
            }
          }
        },
      });
      let finalY = (doc as any).lastAutoTable.finalY;
      // console.log('aqui', finalY)
      startY = finalY;
    });
    // addFooters(doc)
    // doc.save()

    //PAGE 3
    doc.addPage();
    addHeader(doc);

    const imgMap = `${data_bbl.lot_map}`;

    // doc.addImage(imgMap, 'PNG', 26, 25, 161, 85);
    doc.addImage(imgMap, 'PNG', 26, 26, 163.9, 81, 'imgmap', 'SLOW');
    // doc.addImage('/assets/images/pdf/north_symbol2.png', 'PNG', 180.41, 96, 8, 8 , 'north2', 'SLOW');

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(`Figure 3: Google Street View `, 26, 110);
    doc.setFontSize(10);

    doc.setFont('helvetica', 'bold');
    textWithUnderline(doc, 'Building information', 26, 116);
    startY = 121;

    autoTable(doc, {
      styles: {
        fontSize: 7,
        lineWidth: 0.2,
        lineColor: 'black',
        cellPadding: 0.6,
      },
      headStyles: {
        fillColor: [242, 242, 242],
        lineWidth: 0.2,
      },
      columnStyles: {
        0: { cellWidth: 53 },
        1: { cellWidth: 110.9 },
      },
      startY, // Posición en el eje Y (vertical)
      margin: {
        left: 26,
      },
      tableWidth: 166,
      // Posición en el eje X (horizontal)
      head: [
        [
          {
            content: 'Building Data',
            colSpan: 2,
          },
        ],
      ],
      body: [
        ['Land Use Category', `${data_bbl.building.zd_landuse}`],
        ['Building Class', `${data_bbl.building.zd_bldgclass}`],
        ['Year built', `${data_bbl.building.yearbuilt}`],
        ['Year Altered', `${data_bbl.building.year_altered}`],
        ['Number of Buildings', `${data_bbl.building.numbldgs}`],
        ['Number of floors (height)', `${data_bbl.building.numfloors}`],
        ['Basement Type/Grade', `${data_bbl.building.zd_bsmtcode}`],
        ['Residential Units', `${data_bbl.building.unitsres}`],
        ['Total Units', `${data_bbl.building.unitstotal}`],
      ],
      theme: 'plain',
    });
    textWithUnderline(doc, 'Floor Area Distribution', 26, 167);
    startY = 169;
    const colors: [number, number, number][] = [
      [255, 255, 255], //white
      [223, 189, 106], //
      [213, 79, 65], //
      [215, 151, 144],
      [144, 108, 108],
      [85, 85, 85],
      [186, 184, 183],
      [197, 112, 237],
      [80, 153, 233],
    ];
    autoTable(doc, {
      styles: {
        fontSize: 7,
        lineWidth: 0.2,
        lineColor: 'black',
        cellPadding: 0.6,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        lineWidth: {
          bottom: 0.2,
        },
        cellWidth: 0,
      },
      columnStyles: {
        0: { cellWidth: 5 },
        1: { cellWidth: 48 },
        2: { cellWidth: 110.9, halign: 'left' },
      },
      startY, // Posición en el eje Y (vertical)
      margin: {
        left: 26,
      },
      tableWidth: 163.9,
      // Posición en el eje X 2(horizontal) 166
      head: [['', '', ``]],
      body: [
        [
          '',
          'Total Existing',
          `${data_bbl.floor_area_distribution.bldgarea} sf`,
        ],
        ['', 'Residential', `${data_bbl.floor_area_distribution.resarea} sf`],
        ['', 'Commercial', `${data_bbl.floor_area_distribution.comarea} sf`],
        ['', 'Office', `${data_bbl.floor_area_distribution.officearea} sf`],
        ['', 'Retail', `${data_bbl.floor_area_distribution.retailarea} sf`],
        ['', 'Garage', `${data_bbl.floor_area_distribution.garagearea} sf`],
        ['', 'Storage', `${data_bbl.floor_area_distribution.strgearea} sf`],
        ['', 'Factory', `${data_bbl.floor_area_distribution.factryarea} sf`],
        ['', 'Other', `${data_bbl.floor_area_distribution.otherarea} sf`],
      ],
      theme: 'plain',
      didParseCell: (data) => {
        // Aplicar color a las celdas de la columna 0
        if (data.section === 'body' && data.column.index === 0) {
          const colorIndex = data.row.index % colors.length;
          data.cell.styles.fillColor = colors[colorIndex];
        }
      },
      didDrawCell: (data) => {
        if (data.section === 'body' && data.row.index === 0) {
          const cell = data.cell;
          cell.styles.fillColor = 'white';
          if (data.column.index === 0) {
            // Primero dibuja un rectángulo blanco para cubrir cualquier línea existente
            doc.setFillColor(255, 255, 255);
            doc.rect(
              cell.x - 0.2,
              cell.y - 0.2,
              cell.width + 0.2,
              cell.height + 0.2,
              'F'
            );

            // Luego dibuja las líneas de la celda manualmente
            doc.setDrawColor(0, 0, 0); // Color negro para las líneas deseadas
            doc.setLineWidth(0.2);

            // Dibuja las líneas derecha e inferior
            doc.line(
              cell.x + cell.width,
              cell.y,
              cell.x + cell.width,
              cell.y + cell.height
            ); // Línea derecha
            doc.line(
              cell.x,
              cell.y + cell.height,
              cell.x + cell.width,
              cell.y + cell.height
            ); // Línea inferior
          }
        }
      },
    });
    const test_text: string = `Information Context:
• Rough estimate of the building gross floor area and does not necessarily take into account all the criteria for calculating zoning floor area (ZFA) as defined in section .
• Note that the sum of the various floor area fields does not always equal total "Building Gross sf".
• Floor Area of zero can mean it is either not available or not applicable.`;
    doc.setFontSize(8);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor(0, 0, 255); // Restaurar color negro
    doc.setFontSize(7);
    doc.textWithLink('ZR 12-10', 73.2, 220.5, {
      url: 'https://zr.planning.nyc.gov/article-i/chapter-2#12-10',
    });
    doc.setFontSize(8);

    doc.setTextColor(0, 0, 0); // Restaurar color negro
    doc.text(test_text, 26, 214, { maxWidth: 163.9 }); //todo here

    // doc.addImage(`${data_bbl.graph}`, 'PNG', 73, 220, 71, 50, 'graph', 'SLOW');
    doc.setFontSize(10);
    doc.addPage();
    //PAGE 4
    addHeader(doc);
    doc.addImage(
      report1,
      'PNG',
      26,
      25,
      166,
      81,
      '3dimg',
      'SLOW'
    );
    doc.addImage('/assets/images/pdf/north_symbol2.png', 'PNG', 180.41, 96, 8, 8, 'north', 'SLOW');

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(`Figure 4: Map view showing tax lot boundaries `, 26, 110);
    doc.setFontSize(10);

    doc.setFont('helvetica', 'bold');

    textWithUnderline(doc, 'Financial information', 26, 116);
    doc.setFont('helvetica', 'normal');

    startY = 121;
    const financialData: any = data_bbl.financial;
    const bodyData = financialData.map((item: any) => [
      item[`year`],
      item[`taxc${item.year}`],
      item[`prop${item.year}`],
      item[`land${item.year}`],
    ]);
    autoTable(doc, {
      styles: {
        fontSize: 7,
        lineWidth: 0.2,
        lineColor: 'black',
        cellPadding: 0.6,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        lineWidth: 0.2,
        cellWidth: 0,
        halign: 'center',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: { cellWidth: 16, halign: 'center' },
        1: { cellWidth: 49.3, halign: 'center' },
        2: { cellWidth: 49.3, halign: 'center' },
        3: { cellWidth: 49.3, halign: 'center' },
      },
      startY, // Posición en el eje Y (vertical)
      margin: {
        left: 26,
      },
      tableWidth: 163.9,
      // Posición en el eje X 2(horizontal) 166
      head: [
        [
          'Year',
          'Tax Class*',
          'Property Market Value ($)',
          `Land Market Value($)`,
        ],
      ],
      body: bodyData,
      theme: 'plain',
      didParseCell: function (data) {
        if (data.row.section === 'head' && data.cell == data.row.cells[2]) {
          data.cell.styles.fillColor = '#F0D459';
        }
        if (data.row.section === 'head' && data.cell == data.row.cells[3]) {
          data.cell.styles.fillColor = '#CA7266';
        }
      },
    });
    startY = (doc as any).lastAutoTable.finalY;
    const test_text2: string = `Information Context:
Data represent NYC properties assessments for the purpose of calculating Property Tax, Grant eligible properties Exemptions and/or Abatements. This dataset originates from Property Assessors, Property Exemption Specialists, ACRIS reporting, and Department of Building reporting.

*Properties in NYC are divided into 4 classes:
• Class 1: Most residential property of up to three units (family homes and small stores or offices with one or two apartments attached), and most condominiums that are not more than three stories.
• Class 2: All other property that is not in Class 1 and is primarily residential (rentals, cooperatives and condominiums). Class 2 includes:
      a. Sub-Class 2a (4 - 6 unit rental building);
      b. Sub-Class 2b (7 - 10 unit rental building);
      c. Sub-Class 2c (2 - 10 unit cooperative or condominium); and
      d. Class 2 (11 units or more).
• Class 3: Most utility property.
• Class 4: All commercial and industrial properties, such as office, retail, factory buildings and all other properties not included in tax classes 1, 2 or 3.`;
    doc.setFontSize(8);
    doc.setFont('helvetica', 'normal');

    doc.text(test_text2, 26, startY + 4, { maxWidth: 163.9 }); //todo here

    doc.setFontSize(10);
    //financial map

    //page 5
    doc.addPage();
    addHeader(doc);
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    textWithUnderline(doc, 'Floor Area Data', 26, 22);

    const splitzone = JSON.parse(data_bbl.floor_area_data.FAR.SPLITZONE);

    const bldg = JSON.parse(data_bbl.floor_area_data.FAR.BLDGAREA);
    const farData = data_bbl.floor_area_data.FAR.DIST;

    startY = 26;
    const farColors: [number, number, number][] = [
      [232, 206, 157], //white
      [233, 189, 106], //
      [217, 152, 31], //
      [80, 153, 233],
      [213, 79, 65],
      [197, 112, 237],
    ];
    let hasRun: boolean = false;
    farData.forEach((item: any, index: any) => {

      // Inicializar titleTable como un string vacío

      let titleTable = '';

      // Iterar sobre cada elemento en TITLE y construir titleTable
      item.TITLE.forEach((title: any, i: any) => {
        // Agregar el contenido de cada title al titleTable parseFloat(JSON.parse(FAR.MAX[1]).toFixed(2)).toLocaleString('en-US')
        titleTable +=
          title['OVERLAY'] === null
            ? `${title['ZONEDIST']} portion = ${parseFloat(
              JSON.parse(title['A_SG_P']).toFixed(2)
            ).toLocaleString('en-US')}% ( ${parseFloat(
              JSON.parse(title['A_SG_SF']).toFixed(2)
            ).toLocaleString('en-US')} sf)`
            : `${title['ZONEDIST']}/${title['OVERLAY']} portion = ${parseFloat(
              JSON.parse(title['A_SG_P']).toFixed(2)
            ).toLocaleString('en-US')} % ( ${parseFloat(
              JSON.parse(title['A_SG_SF']).toFixed(2)
            ).toLocaleString('en-US')} sf)`;
        // Añadir una línea nueva si no es el último elemento
        if (i < item.TITLE.length - 1) {
          titleTable += ' \n'; // O cualquier separador que prefieras
        }
      });


      let farBody: any = [
        [
          'Residential (Quality Housing)',
          `${item.R_FAR_QH !== null
            ? item.R_FAR_QH
            : '-'
          }`,
          `${item.R_ZFA_QH !== null
            ? parseFloat(JSON.parse(item.R_ZFA_QH).toFixed(2)).toLocaleString(
              'en-US'
            )
            : '-'
          }`,
          splitzone
            ? '-'
            : `${item.R_ZFA_QH !== null
              ? parseFloat(
                (JSON.parse(item.R_ZFA_QH) - bldg).toFixed(2)
              ).toLocaleString('en-US')
              : '-'
            }`,
        ],
        [
          'Residential (Height Factor)',
          `${item.R_FAR_HF !== null
            ? item.R_FAR_HF
            : '-'
          }`,
          `${item.R_ZFA_HF !== null
            ? parseFloat(JSON.parse(item.R_ZFA_HF).toFixed(2)).toLocaleString(
              'en-US'
            )
            : '-'
          }`,
          splitzone
            ? '-'
            : `${item.R_ZFA_HF !== null
              ? parseFloat(
                (JSON.parse(item.R_ZFA_HF) - bldg).toFixed(2)
              ).toLocaleString('en-US')
              : '-'
            }`,
        ],
        [
          'Residential (Inclusionary Housing)',
          `${item.R_FAR_IH !== null
            ? item.R_FAR_IH
            : '-'
          }`,
          `${item.R_ZFA_IH !== null
            ? parseFloat(JSON.parse(item.R_ZFA_IH).toFixed(2)).toLocaleString(
              'en-US'
            )
            : '-'
          }`,
          splitzone
            ? '-'
            : `${item.R_ZFA_IH !== null
              ? parseFloat(
                (JSON.parse(item.R_ZFA_IH) - bldg).toFixed(2)
              ).toLocaleString('en-US')
              : '-'
            }`,
        ],
        [
          'Community Facility',
          `${item.CF_FAR !== null
            ? item.CF_FAR
            : '-'
          }`,
          `${item.CF_ZFA !== null
            ? parseFloat(JSON.parse(item.CF_ZFA).toFixed(2)).toLocaleString(
              'en-US'
            )
            : '-'
          }`,
          splitzone
            ? '-'
            : `${item.CF_ZFA !== null
              ? parseFloat(
                (JSON.parse(item.CF_ZFA) - bldg).toFixed(2)
              ).toLocaleString('en-US')
              : '-'
            }`,
        ],
        [
          'Commercial',
          `${item.C_FAR !== null
            ? item.C_FAR
            : '-'
          }`,
          `${item.C_ZFA !== null
            ? parseFloat(JSON.parse(item.C_ZFA).toFixed(2)).toLocaleString(
              'en-US'
            )
            : '-'
          }`,
          splitzone
            ? '-'
            : `${item.C_ZFA !== null
              ? parseFloat(
                (JSON.parse(item.C_ZFA) - bldg).toFixed(2)
              ).toLocaleString('en-US')
              : '-'
            }`,
        ],
        [
          'Manufacturing',
          `${item.M_FAR !== null
            ? item.M_FAR
            : '-'
          }`,
          `${item.M_ZFA !== null
            ? parseFloat(JSON.parse(item.M_ZFA).toFixed(2)).toLocaleString(
              'en-US'
            )
            : '-'
          }`,
          splitzone
            ? '-'
            : `${item.M_ZFA !== null
              ? parseFloat(
                (JSON.parse(item.M_ZFA) - bldg).toFixed(2)
              ).toLocaleString('en-US')
              : '-'
            }`,
        ],
      ];

      autoTable(doc, {
        styles: {
          fontSize: 7,
          lineWidth: 0.2,
          lineColor: 'black',
          cellPadding: 0.6,
        },
        headStyles: {
          fillColor: [242, 242, 242],
          lineWidth: 0.2,
          cellWidth: 0,
          halign: 'center',
          fontStyle: 'bold',
        },
        columnStyles: {
          0: { cellWidth: 40, halign: 'left' },
          1: { cellWidth: 42, halign: 'center' },
          2: { cellWidth: 42, halign: 'center' },
          3: { halign: 'center' },
        },
        startY, // Posición en el eje Y (vertical)
        margin: {
          left: 26,
        },
        tableWidth: 163.9,
        head:
          index === 0
            ? [
              ['', 'FAR', 'ZFA (sf.)', 'Remain (sf.)'],
              [
                '',
                {
                  content: titleTable,
                  colSpan: 3,
                  styles: { halign: 'center' },
                },
              ],
            ]
            : [
              [
                '',
                {
                  content: titleTable,
                  colSpan: 3,
                },
              ],
            ],
        body: farBody,
        theme: 'plain',
        didParseCell: (data) => {
          // Aplicar color a las celdas de la columna 0
          if (data.section === 'body' && data.column.index === 0) {
            const colorIndex = data.row.index % farColors.length;
            data.cell.styles.fillColor = farColors[colorIndex];
          }
          // Modificar las líneas de la celda (0, 0) del encabezado
        },
        didDrawCell: (data) => {
          if (
            !hasRun &&
            data.section === 'head' &&
            (data.row.index === 0 || data.row.index === 1)
          ) {
            const cell = data.cell;
            cell.styles.fillColor = 'white';
            if (data.column.index === 0) {
              if (data.row.index === 1) {
                hasRun = true;
              }
              // Primero dibuja un rectángulo blanco para cubrir cualquier línea existente
              doc.setFillColor(255, 255, 255);
              doc.rect(
                cell.x - 0.2,
                cell.y - 0.2,
                cell.width + 0.2,
                cell.height + 0.2,
                'F'
              );

              // Luego dibuja las líneas de la celda manualmente
              doc.setDrawColor(0, 0, 0); // Color negro para las líneas deseadas
              doc.setLineWidth(0.2);

              // Dibuja las líneas derecha e inferior
              doc.line(
                cell.x + cell.width,
                cell.y,
                cell.x + cell.width,
                cell.y + cell.height
              ); // Línea derecha
              doc.line(
                cell.x,
                cell.y + cell.height,
                cell.x + cell.width,
                cell.y + cell.height
              ); // Línea inferior
            }
          } else {
            if (hasRun && data.section === 'head' && data.row.index === 0) {
              const cell = data.cell;
              cell.styles.fillColor = 'white';
              if (data.column.index === 0) {
                // Primero dibuja un rectángulo blanco para cubrir cualquier línea existente
                doc.setFillColor(255, 255, 255);
                doc.rect(
                  cell.x - 0.2,
                  cell.y - 0.1,
                  cell.width + 0.1,
                  cell.height + 0.1,
                  'F'
                );

                // Luego dibuja las líneas de la celda manualmente
                doc.setDrawColor(0, 0, 0); // Color negro para las líneas deseadas
                doc.setLineWidth(0.2);

                // Dibuja las líneas derecha e inferior
                doc.line(
                  cell.x + cell.width,
                  cell.y,
                  cell.x + cell.width,
                  cell.y + cell.height
                ); // Línea derecha
                doc.line(
                  cell.x,
                  cell.y + cell.height,
                  cell.x + cell.width,
                  cell.y + cell.height
                ); // Línea inferior
                //linea arriba
                // Dibuja la línea superior
                doc.line(
                  cell.x - 0.1,
                  cell.y, // Coordenada inicial (x, y) de la línea superior
                  cell.x + cell.width,
                  cell.y // La misma y para mantener la línea horizontal en la parte superior
                );
              }
            }
          }
        },
      });
      // Actualiza la posición `startY` para la siguiente tabla
      let finalY = (doc as any).lastAutoTable.finalY;
      // console.log('aqui', finalY)
      startY = finalY;
    });
    if (splitzone) {
      let adjusted: any = data_bbl.floor_area_data.FAR.ADJUSTED;
      let adjustedTitle: any = `Adjusted =  100% ( ${parseFloat(
        JSON.parse(data_bbl.floor_area_data.FAR.A_TOTAL).toFixed(2)
      ).toLocaleString('en-US')} sf)`;
      let adjustedBody: any = [
        [
          'Residential (Quality Housing)',
          `${adjusted.R_ADJ_FARQ !== null
            ? JSON.parse(adjusted.R_ADJ_FARQ).toLocaleString('en-US')
            : '-'
          }`,
          `${adjusted.R_ADJ_ZFAQ !== null
            ? parseFloat(
              JSON.parse(adjusted.R_ADJ_ZFAQ).toFixed(2)
            ).toLocaleString('en-US')
            : '-'
          }`,
          `${adjusted.R_ADJ_ZFAQ !== null
            ? parseFloat(
              (JSON.parse(adjusted.R_ADJ_ZFAQ) - bldg).toFixed(2)
            ).toLocaleString('en-US')
            : '-'
          }`,
        ],
        [
          'Residential (Height Factor)',
          `${adjusted.R_ADJ_FARH !== null
            ? JSON.parse(adjusted.R_ADJ_FARH).toLocaleString('en-US')
            : '-'
          }`,
          `${adjusted.R_ADJ_ZFAH !== null
            ? parseFloat(
              JSON.parse(adjusted.R_ADJ_ZFAH).toFixed(2)
            ).toLocaleString('en-US')
            : '-'
          }`,
          `${adjusted.R_ADJ_ZFAH !== null
            ? parseFloat(
              (JSON.parse(adjusted.R_ADJ_ZFAH) - bldg).toFixed(2)
            ).toLocaleString('en-US')
            : '-'
          }`,
        ],
        [
          'Residential (Inclusionary Housing)',
          `${adjusted.R_FAR_IH !== null
            ? adjusted.R_FAR_IH
            : '-'
          }`,
          `${adjusted.R_ZFA_IH !== null
            ? parseFloat(
              JSON.parse(adjusted.R_ZFA_IH).toFixed(2)
            ).toLocaleString('en-US')
            : '-'
          }`,
          `${adjusted.R_ZFA_IH !== null
            ? parseFloat(
              (JSON.parse(adjusted.R_ZFA_IH) - bldg).toFixed(2)
            ).toLocaleString('en-US')
            : '-'
          }`,
        ],
        [
          'Community Facility',
          `${adjusted.CF_ADJ_FAR !== null
            ? adjusted.CF_ADJ_FAR
            : '-'
          }`,
          `${adjusted.CF_ADJ_ZFA !== null
            ? parseFloat(
              JSON.parse(adjusted.CF_ADJ_ZFA).toFixed(2)
            ).toLocaleString('en-US')
            : '-'
          }`,
          `${adjusted.CF_ADJ_ZFA !== null
            ? parseFloat(
              (JSON.parse(adjusted.CF_ADJ_ZFA) - bldg).toFixed(2)
            ).toLocaleString('en-US')
            : '-'
          }`,
        ],
        [
          'Commercial',
          `${adjusted.C_ADJ_FAR !== null
            ? adjusted.C_ADJ_FAR
            : '-'
          }`,
          `${adjusted.C_ADJ_ZFA !== null
            ? parseFloat(
              JSON.parse(adjusted.C_ADJ_ZFA).toFixed(2)
            ).toLocaleString('en-US')
            : '-'
          }`,
          `${adjusted.C_ADJ_ZFA !== null
            ? parseFloat(
              (JSON.parse(adjusted.C_ADJ_ZFA) - bldg).toFixed(2)
            ).toLocaleString('en-US')
            : '-'
          }`,
        ],
        [
          'Manufacturing',
          `${adjusted.M_ADJ_FAR !== null
            ? adjusted.M_ADJ_FAR
            : '-'
          }`,
          `${adjusted.M_ADJ_ZFA !== null
            ? parseFloat(
              JSON.parse(adjusted.C_ADJ_ZFA).toFixed(2)
            ).toLocaleString('en-US')
            : '-'
          }`,
          `${adjusted.M_ADJ_ZFA !== null
            ? parseFloat(
              (JSON.parse(adjusted.M_ADJ_ZFA) - bldg).toFixed(2)
            ).toLocaleString('en-US')
            : '-'
          }`,
        ],
      ];
      autoTable(doc, {
        styles: {
          fontSize: 7,
          lineWidth: 0.2,
          lineColor: 'black',
          cellPadding: 0.6,
        },
        headStyles: {
          fillColor: [242, 242, 242],
          lineWidth: 0.2,
          cellWidth: 0,
          halign: 'center',
          fontStyle: 'bold',
        },
        columnStyles: {
          0: { cellWidth: 40, halign: 'left' },
          1: { cellWidth: 42, halign: 'center' },
          2: { cellWidth: 42, halign: 'center' },
          3: { halign: 'center' },
        },
        startY, // Posición en el eje Y (vertical)
        margin: {
          left: 26,
        },
        tableWidth: 163.9,
        head: [
          [
            '',
            {
              content: adjustedTitle,
              colSpan: 3,
            },
          ],
        ],
        body: adjustedBody,
        theme: 'plain',
        didParseCell: (data) => {
          // Aplicar color a las celdas de la columna 0
          if (data.section === 'body' && data.column.index === 0) {
            const colorIndex = data.row.index % farColors.length;
            data.cell.styles.fillColor = farColors[colorIndex];
          }
        },
        didDrawCell: (data) => {
          if (data.section === 'head' && data.row.index === 0) {
            const cell = data.cell;
            cell.styles.fillColor = 'white';
            if (data.column.index === 0) {
              // Primero dibuja un rectángulo blanco para cubrir cualquier línea existente
              doc.setFillColor(255, 255, 255);
              doc.rect(
                cell.x - 0.2,
                cell.y - 0.1,
                cell.width + 0.1,
                cell.height + 0.1,
                'F'
              );

              // Luego dibuja las líneas de la celda manualmente
              doc.setDrawColor(0, 0, 0); // Color negro para las líneas deseadas
              doc.setLineWidth(0.2);

              // Dibuja las líneas derecha e inferior
              doc.line(
                cell.x + cell.width,
                cell.y,
                cell.x + cell.width,
                cell.y + cell.height
              ); // Línea derecha
              doc.line(
                cell.x,
                cell.y + cell.height,
                cell.x + cell.width,
                cell.y + cell.height
              ); // Línea inferior
              //linea arriba
              // Dibuja la línea superior
              doc.line(
                cell.x - 0.1,
                cell.y, // Coordenada inicial (x, y) de la línea superior
                cell.x + cell.width,
                cell.y // La misma y para mantener la línea horizontal en la parte superior
              );
            }
          }
        },
      });
      let finalY = (doc as any).lastAutoTable.finalY;
      // console.log('aqui', finalY)
      startY = finalY;
    }
    startY = startY + 5;
    const FAR = data_bbl.floor_area_data.FAR;
    autoTable(doc, {
      styles: {
        fontSize: 7,
        lineWidth: 0.2,
        lineColor: 'black',
        cellPadding: 0.6,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        lineWidth: 0.2,
        cellWidth: 0,
        halign: 'center',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: { cellWidth: 40, halign: 'center' },
        1: { cellWidth: 42, halign: 'center' },
        2: { cellWidth: 42, halign: 'center' },
        3: { halign: 'center' },
      },
      startY, // Posición en el eje Y (vertical)
      margin: {
        left: 26,
      },
      tableWidth: 163.9,
      head: [],
      body: [
        [
          'Max',
          FAR.MAX[0],
          parseFloat(JSON.parse(FAR.MAX[1]).toFixed(2)).toLocaleString('en-US'),
          parseFloat((JSON.parse(FAR.MAX[1]) - bldg).toFixed(2)).toLocaleString(
            'en-US'
          ),
        ],
        [
          'Max IH',
          JSON.parse(FAR.MAX_IH[0]).toLocaleString('en-US'),
          parseFloat(JSON.parse(FAR.MAX_IH[1]).toFixed(2)).toLocaleString(
            'en-US'
          ),
          parseFloat(
            (JSON.parse(FAR.MAX_IH[1]) - bldg).toFixed(2)
          ).toLocaleString('en-US'),
        ],
      ],
      theme: 'plain',
    });
    let finalY = (doc as any).lastAutoTable.finalY;
    doc.setFontSize(8);
    const footerTable: string = `
Information Context:
This dataset presents estimated Floor Area Ratio (FAR) and Zoning Floor Area (ZFA) figures, based on allowable uses.

Note:
• Values in the "Remain" column are calculated by subtracting the "Total Existing" building gross floor area from the allowable Floor Area Ratio (FAR). This indicates how much zoning floor area (ZFA) may be available for each generic use group.
• "Remain" values do not account for the sale or purchase of potential development rights.
`;
    doc.setFontSize(8);
    doc.setFont('helvetica', 'normal');

    doc.text(footerTable, 26, finalY + 2, { maxWidth: 163.9 });

    doc.addPage();
    addHeader(doc);
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.addImage(
      report2d,
      'PNG',
      26,
      25,
      166,
      81,
      'zoning_analysis',
      'SLOW'
    );
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(`Figure 5: Envelope 2D view `, 26, 110);
    doc.setFontSize(10);

    doc.setFont('helvetica', 'bold');
    textWithUnderline(doc, 'Zoning Analysis', 26, 115);
    doc.setFont('helvetica', 'bold');
    doc.text('1. Use', 26, 121, { maxWidth: 163.9 });

    startY = 126;

    let zrData: any[] = data_bbl.zoning_analysis.use.zr_section;
    let zrDistrict = '';
    let allowed = '';
    let existing = '';
    data_bbl.zoning_analysis.use.district.forEach((element: any) => {
      zrDistrict += element + '\n';
    });
    data_bbl.zoning_analysis.use.allowed.forEach((element: any) => {
      allowed += element + '\n';
    });
    data_bbl.zoning_analysis.use.existing.forEach((element: any) => {
      existing += element + '\n';
    });
    autoTable(doc, {
      styles: {
        fontSize: 7,
        lineWidth: 0.2,
        lineColor: 'black',
        cellPadding: 0.6,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        lineWidth: 0.2,
        cellWidth: 0,
        halign: 'justify',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: { cellWidth: 22, halign: 'left' },
        1: { cellWidth: 18, halign: 'left' },
        2: { cellWidth: 86, halign: 'left' },
        3: { halign: 'left' },
      },
      startY, // Posición en el eje Y (vertical)
      margin: {
        left: 26,
      },
      tableWidth: 163.9,
      head: [['ZR section', 'District', 'Allowed / Required', 'Existing']],
      body: [['', '', '', '']],
      // body: [['', zrDistrict, allowed, existing]],
      theme: 'plain',
      // didDrawCell: function (data) {
      //   if (data.section === 'body' && data.column.index === 0) {
      //     // Solo en la primera columna
      //     data.cell.styles.textColor = [255, 255, 255];
      //     // let startYBody = data.cell.y
      //     let zrX = data.cell.x + data.cell.padding('left'); // Posición X de la celda
      //     let zrY = data.cell.y + 3; // Posición Y centrada en la celda

      //     zrData.forEach((element, index) => {
      //       doc.setTextColor(0, 0, 255); // Color del enlace
      //       doc.textWithLink(element.zr, zrX, zrY, { url: element.url });
      //     });

      //     doc.setTextColor(0, 0, 0); // Restaurar color negro
      //   }
      // },
    });
    startY = (doc as any).lastAutoTable.finalY;
    let zrDistrict2 = 'C5-3\nMID';
    let allowed2 = 'None Required';
    let existing2 = 'n/a';
    doc.text('2. Lot Coverage (max.)', 26, startY + 6, { maxWidth: 166 });
    startY += 10;
    autoTable(doc, {
      styles: {
        fontSize: 7,
        lineWidth: 0.2,
        lineColor: 'black',
        cellPadding: 0.6,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        lineWidth: 0.2,
        cellWidth: 0,
        halign: 'justify',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: { cellWidth: 22, halign: 'left' },
        1: { cellWidth: 18, halign: 'left' },
        2: { cellWidth: 86, halign: 'left' },
        3: { halign: 'left' },
      },
      startY, // Posición en el eje Y (vertical)
      margin: {
        left: 26,
      },
      tableWidth: 163.9,
      head: [['ZR section', 'District', 'Allowed / Required', 'Existing']],
      body: [['', '', '', '']],
      // body: [['', zrDistrict2, allowed2, existing2]],
      theme: 'plain',
      // didDrawCell: function (data) {
      //   if (data.section === 'body' && data.column.index === 0) {
      //     // Solo en la primera columna
      //     data.cell.styles.textColor = [255, 255, 255];
      //     let zrX = data.cell.x + data.cell.padding('left'); // Posición X de la celda
      //     let zrY = data.cell.y + 3; // Posición Y centrada en la celda

      //     zrData.forEach((element, index) => {
      //       doc.setTextColor(0, 0, 255); // Color del enlace
      //       doc.textWithLink(element.zr, zrX, zrY, { url: element.url });
      //     });

      //     doc.setTextColor(0, 0, 0); // Restaurar color negro
      //   }
      // },
    });
    startY = (doc as any).lastAutoTable.finalY;
    doc.text(
      '3. Floor Area Ratio (FAR) / Zoning Floor Area (ZFA) (max.)',
      26,
      startY + 6,
      { maxWidth: 163.9 }
    );
    let zrDistrict3 = 'C5-3\nMID';
    let allowed3 =
      'Residential: 10 FAR (117,150 sf)\nCommunity Facility: 15 FAR (175,725 sf)\nCommercial: 15 FAR (175,725 sf)\nManufacturing: n/a';
    let existing3 =
      '*Community Facility\nand Commercial use: 20.20 FAR\n(236,665 sf)';
    startY += 10;
    autoTable(doc, {
      styles: {
        fontSize: 7,
        lineWidth: 0.2,
        lineColor: 'black',
        cellPadding: 0.6,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        lineWidth: 0.2,
        cellWidth: 0,
        halign: 'justify',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: { cellWidth: 22, halign: 'left' },
        1: { cellWidth: 18, halign: 'left' },
        2: { cellWidth: 86, halign: 'left' },
        3: { halign: 'left' },
      },
      startY, // Posición en el eje Y (vertical)
      margin: {
        left: 26,
      },
      tableWidth: 163.9,
      head: [['ZR section', 'District', 'Allowed / Required', 'Existing']],
      body: [['', '', '', '']],
      // body: [['', zrDistrict3, allowed3, existing3]],
      theme: 'plain',
      // didDrawCell: function (data) {
      //   if (data.section === 'body' && data.column.index === 0) {
      //     // Solo en la primera columna
      //     data.cell.styles.textColor = [255, 255, 255];
      //     let zrX = data.cell.x + data.cell.padding('left'); // Posición X de la celda
      //     let zrY = data.cell.y + 3; // Posición Y centrada en la celda

      //     zrData.forEach((element, index) => {
      //       doc.setTextColor(0, 0, 255); // Color del enlace
      //       doc.textWithLink('ZR 81-211', zrX, zrY, {
      //         url: `https://zr.planning.nyc.gov/article-viii/chapter-1#81-211`,
      //       });
      //     });

      //     doc.setTextColor(0, 0, 0); // Restaurar color negro
      //   }
      // },
    });

    const test_text3 = `*The current FAR value provides a basic estimate of the building's gross floor area but may not encompass all criteria for calculating zoning floor area (ZFA). Despite this, it seems the building surpasses the maximum allowable FAR, rendering it non-compliant with current zoning regulations. However, the building’s massing may be altered as long as there is no increase in FAR.`;
    doc.setFontSize(8);
    doc.setFont('helvetica', 'normal');
    startY = (doc as any).lastAutoTable.finalY;
    // startY += 3;
    // doc.text(test_text3, 26, startY + 2, { maxWidth: 163.9 }); //todo here
    let zrDistrict4 = 'C5-3\nMID';
    let allowed4 = 'Residential Dwelling Unit Factor: 680 (206 units max)';
    let existing4 = 'n/a';
    startY += 6;
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text('4. Density regulations (max.)', 26, startY, {
      maxWidth: 163.9,
    });
    startY += 4;
    autoTable(doc, {
      styles: {
        fontSize: 7,
        lineWidth: 0.2,
        lineColor: 'black',
        cellPadding: 0.6,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        lineWidth: 0.2,
        cellWidth: 0,
        halign: 'justify',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: { cellWidth: 22, halign: 'left' },
        1: { cellWidth: 18, halign: 'left' },
        2: { cellWidth: 86, halign: 'left' },
        3: { halign: 'left' },
      },
      startY, // Posición en el eje Y (vertical)
      margin: {
        left: 26,
      },
      tableWidth: 163.9,
      head: [['ZR section', 'District', 'Allowed / Required', 'Existing']],
      body: [['', '', '', '']],
      // body: [['', zrDistrict4, allowed4, existing4]],
      theme: 'plain',
      // didDrawCell: function (data) {
      //   if (data.section === 'body' && data.column.index === 0) {
      //     // Solo en la primera columna
      //     data.cell.styles.textColor = [255, 255, 255];
      //     let zrX = data.cell.x + data.cell.padding('left'); // Posición X de la celda
      //     let zrY = data.cell.y + 3; // Posición Y centrada en la celda

      //     zrData.forEach((element, index) => {
      //       doc.setTextColor(0, 0, 255); // Color del enlace
      //       doc.textWithLink('ZR 23-22', zrX, zrY, {
      //         url: `https://zr.planning.nyc.gov/article-ii/chapter-3#23-22`,
      //       });
      //     });

      //     doc.setTextColor(0, 0, 0); // Restaurar color negro
      //   }
      // },
    });
    let zrDistrict5 = 'C5-3\nMID';
    let allowed5 = `Min required for Residential use:\nLot Area:1,700 sf\nLot Width: 18`;
    let existing5 = 'n/a';
    startY = (doc as any).lastAutoTable.finalY;
    doc.text('5. Lot Regulations (min.)', 26, startY + 6, { maxWidth: 163.9 });
    startY += 10;
    autoTable(doc, {
      styles: {
        fontSize: 7,
        lineWidth: 0.2,
        lineColor: 'black',
        cellPadding: 0.6,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        lineWidth: 0.2,
        cellWidth: 0,
        halign: 'justify',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: { cellWidth: 22, halign: 'left' },
        1: { cellWidth: 18, halign: 'left' },
        2: { cellWidth: 86, halign: 'left' },
        3: { halign: 'left' },
      },
      startY, // Posición en el eje Y (vertical)
      margin: {
        left: 26,
      },
      tableWidth: 163.9,
      head: [['ZR section', 'District', 'Allowed / Required', 'Existing']],
      body: [['', '', '', '']],
      // body: [['', zrDistrict5, allowed5, existing5]],
      theme: 'plain',
      // didDrawCell: function (data) {
      //   if (data.section === 'body' && data.column.index === 0) {
      //     // Solo en la primera columna
      //     data.cell.styles.textColor = [255, 255, 255];
      //     let zrX = data.cell.x + data.cell.padding('left'); // Posición X de la celda
      //     let zrY = data.cell.y + 3; // Posición Y centrada en la celda

      //     zrData.forEach((element, index) => {
      //       doc.setTextColor(0, 0, 255); // Color del enlace
      //       doc.textWithLink(`ZR 23-32`, zrX, zrY, {
      //         url: `https://zr.planning.nyc.gov/article-ii/chapter-3#23-32`,
      //       });
      //     });

      //     doc.setTextColor(0, 0, 0); // Restaurar color negro
      //   }
      // },
    });
    let zrDistrict6 = 'C5-3\nMID';
    let allowed6 = `Side Yard: None (8' min. if provided)\n\nRear Yard:\n\t- Interior Portion:R:30'/ C & CF: 20'\n\t- Corner Portion: None within 100' of corner`;
    let existing6 = `Side Yard: n/a\n\nRear Yard:\nThe existing Commercial building encroaches +/- 11' into the required 20' rear yard (non-compilant)`;
    startY = (doc as any).lastAutoTable.finalY;

    doc.text('6. Yard Regulations (min.)', 26, startY + 6, { maxWidth: 163.9 });
    startY += 10
    autoTable(doc, {
      styles: {
        fontSize: 7,
        lineWidth: 0.2,
        lineColor: 'black',
        cellPadding: 0.6,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        lineWidth: 0.2,
        cellWidth: 0,
        halign: 'justify',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: { cellWidth: 22, halign: 'left' },
        1: { cellWidth: 18, halign: 'left' },
        2: { cellWidth: 86, halign: 'left' },
        3: { halign: 'left' },
      },
      startY, // Posición en el eje Y (vertical)
      margin: {
        left: 26,
      },
      tableWidth: 163.9,
      head: [['ZR section', 'District', 'Allowed / Required', 'Existing']],
      body: [['', '', '', '']],
      // body: [['', zrDistrict6, allowed6, existing6]],
      theme: 'plain',
      // didDrawCell: function (data) {
      //   if (data.section === 'body' && data.column.index === 0) {
      //     // Solo en la primera columna
      //     data.cell.styles.textColor = [255, 255, 255];
      //     let zrX = data.cell.x + data.cell.padding('left'); // Posición X de la celda
      //     let zrY = data.cell.y + 3; // Posición Y centrada en la celda

      //     zrData.forEach((element, index) => {
      //       doc.setTextColor(0, 0, 255); // Color del enlace
      //       doc.textWithLink(`ZR 33-25`, zrX, zrY, {
      //         url: `https://zr.planning.nyc.gov/article-iii/chapter-3#33-25`,
      //       });
      //     });

      //     doc.setTextColor(0, 0, 0); // Restaurar color negro
      //   }
      // },
    });
    startY = (doc as any).lastAutoTable.finalY;

    doc.text('7. Height and Setback', 26, startY + 6, { maxWidth: 166 });
    startY += 10
    autoTable(doc, {
      styles: {
        fontSize: 7,
        lineWidth: 0.2,
        lineColor: 'black',
        cellPadding: 0.6,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        lineWidth: 0.2,
        cellWidth: 0,
        halign: 'justify',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: { cellWidth: 22, halign: 'left' },
        1: { cellWidth: 18, halign: 'left' },
        2: { cellWidth: 86, halign: 'left' },
        3: { halign: 'left' },
      },
      startY, // Posición en el eje Y (vertical)
      margin: {
        left: 26,
      },
      tableWidth: 163.9,
      head: [['ZR section', 'District', 'Allowed / Required', 'Existing']],
      body: [['', '', '', '']],
      // body: [['', zrDistrict7, allowed7, existing7]],
      theme: 'plain',
      // didDrawCell: function (data) {
      //   if (data.section === 'body' && data.column.index === 0) {
      //     // Solo en la primera columna
      //     data.cell.styles.textColor = [255, 255, 255];
      //     let zrX = data.cell.x + data.cell.padding('left'); // Posición X de la celda
      //     let zrY = data.cell.y + 3; // Posición Y centrada en la celda

      //     zrData.forEach((element, index) => {
      //       doc.setTextColor(0, 0, 255); // Color del enlace
      //       doc.textWithLink(`ZR 81-25`, zrX, zrY, {
      //         url: `https://zr.planning.nyc.gov/article-viii/chapter-1#81-25`,
      //       });
      //     });

      //     doc.setTextColor(0, 0, 0); // Restaurar color negro
      //   }
      // },
    });
    startY = (doc as any).lastAutoTable.finalY;
    startY += 6

    if (startY + 84 <= 254.4) {
      doc.addImage(
        features.imageUrl,
        'PNG',
        26,
        startY,
        166,
        81,
        'report2d',
        'SLOW'
      );
      doc.setFont('helvetica', 'normal');

      doc.setFontSize(8);
      doc.text(`Figure 6: Envelope 3D view `, 26, startY + 81);
      doc.setFontSize(10);


    } else {
      doc.addPage();
      addHeader(doc);
      doc.addImage(
        features.imageUrl,
        'PNG',
        26,
        25,
        166,
        81,
        'report2d',
        'SLOW'
      );
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(8);
      doc.text(`Figure 6: Envelope 3D view `, 26, 109);
      doc.setFontSize(10);

    }
    // doc.addImage(
    //   features.imageUrl,
    //   'PNG',
    //   26,
    //   startY,
    //   166,
    //   81,
    //   'report2d',
    //   'SLOW'
    // );

    // //PAGE 6
    // doc.addPage();
    // addHeader(doc);
    // // doc.setFontSize(10);
    // // doc.setFont('helvetica', 'bold');
    // // doc.text('6. Yard Regulations (min.)', 26, 26, { maxWidth: 163.9 });
    // // startY = 28;
    // // autoTable(doc, {
    // //   styles: {
    // //     fontSize: 7,
    // //     lineWidth: 0.2,
    // //     lineColor: 'black',
    // //     cellPadding: 0.6,
    // //   },
    // //   headStyles: {
    // //     fillColor: [255, 255, 255],
    // //     lineWidth: 0.2,
    // //     cellWidth: 0,
    // //     halign: 'justify',
    // //     fontStyle: 'bold',
    // //   },
    // //   columnStyles: {
    // //     0: { cellWidth: 22, halign: 'left' },
    // //     1: { cellWidth: 18, halign: 'left' },
    // //     2: { cellWidth: 86, halign: 'left' },
    // //     3: { halign: 'left' },
    // //   },
    // //   startY, // Posición en el eje Y (vertical)
    // //   margin: {
    // //     left: 26,
    // //   },
    // //   tableWidth: 163.9,
    // //   head: [['ZR section', 'District', 'Allowed / Required', 'Existing']],
    // //   body: [['', '', '', '']],
    // //   // body: [['', zrDistrict6, allowed6, existing6]],
    // //   theme: 'plain',
    // //   // didDrawCell: function (data) {
    // //   //   if (data.section === 'body' && data.column.index === 0) {
    // //   //     // Solo en la primera columna
    // //   //     data.cell.styles.textColor = [255, 255, 255];
    // //   //     let zrX = data.cell.x + data.cell.padding('left'); // Posición X de la celda
    // //   //     let zrY = data.cell.y + 3; // Posición Y centrada en la celda

    // //   //     zrData.forEach((element, index) => {
    // //   //       doc.setTextColor(0, 0, 255); // Color del enlace
    // //   //       doc.textWithLink(`ZR 33-25`, zrX, zrY, {
    // //   //         url: `https://zr.planning.nyc.gov/article-iii/chapter-3#33-25`,
    // //   //       });
    // //   //     });

    // //   //     doc.setTextColor(0, 0, 0); // Restaurar color negro
    // //   //   }
    // //   // },
    // // });
    // // startY = (doc as any).lastAutoTable.finalY;
    // // doc.text('7. Height and Setback', 26, startY + 6, { maxWidth: 166 });
    // // let zrDistrict7 = 'C5-3\nMID';
    // // let allowed7 = `Height and Setback Regulations--Daylight\nCompensation\n\nPark Avenue: 140’ wide street:\n\t- Max height of front wall = 150’ (Within 100’ of corner)\n\t- Min setback above front wall height = 10’\n\t- Sky exposure plane (see Table C of ZR 81-26)\n\nEast 55th Street: 60’ narrow street\n\t- Max height of front wall = 90’\n\t- Min setback above front wall height = 10’\n\t- Sky exposure plane (see Table A of ZR 81-26)\n`;
    // // let existing7 = `Existing building height to roof = 233’\nSee massing for additional information`;
    // // startY += 10;
    // // autoTable(doc, {
    // //   styles: {
    // //     fontSize: 7,
    // //     lineWidth: 0.2,
    // //     lineColor: 'black',
    // //     cellPadding: 0.6,
    // //   },
    // //   headStyles: {
    // //     fillColor: [255, 255, 255],
    // //     lineWidth: 0.2,
    // //     cellWidth: 0,
    // //     halign: 'justify',
    // //     fontStyle: 'bold',
    // //   },
    // //   columnStyles: {
    // //     0: { cellWidth: 22, halign: 'left' },
    // //     1: { cellWidth: 18, halign: 'left' },
    // //     2: { cellWidth: 86, halign: 'left' },
    // //     3: { halign: 'left' },
    // //   },
    // //   startY, // Posición en el eje Y (vertical)
    // //   margin: {
    // //     left: 26,
    // //   },
    // //   tableWidth: 163.9,
    // //   head: [['ZR section', 'District', 'Allowed / Required', 'Existing']],
    // //   body: [['', '', '', '']],
    // //   // body: [['', zrDistrict7, allowed7, existing7]],
    // //   theme: 'plain',
    // //   // didDrawCell: function (data) {
    // //   //   if (data.section === 'body' && data.column.index === 0) {
    // //   //     // Solo en la primera columna
    // //   //     data.cell.styles.textColor = [255, 255, 255];
    // //   //     let zrX = data.cell.x + data.cell.padding('left'); // Posición X de la celda
    // //   //     let zrY = data.cell.y + 3; // Posición Y centrada en la celda

    // //   //     zrData.forEach((element, index) => {
    // //   //       doc.setTextColor(0, 0, 255); // Color del enlace
    // //   //       doc.textWithLink(`ZR 81-25`, zrX, zrY, {
    // //   //         url: `https://zr.planning.nyc.gov/article-viii/chapter-1#81-25`,
    // //   //       });
    // //   //     });

    // //   //     doc.setTextColor(0, 0, 0); // Restaurar color negro
    // //   //   }
    // //   // },
    // // });
    // // const test_text4 = `*An applicant for plan approval by the Department of Buildings may elect to be governed by the provisions of either Section ZR 81-26 (Height and Setback Regulations--Daylight Compensation) or ZR 81-27 (Alternate Height and Setback Regulations--Daylight Evaluation). The above regulations are limited to ZR 81-26 only.`;
    // // doc.setFontSize(8);
    // // doc.setFont('helvetica', 'normal');
    // // startY = (doc as any).lastAutoTable.finalY;
    // // startY += 3;
    // // // doc.text(test_text4, 26, startY + 2, { maxWidth: 163.9 });

    // // startY+=15
    // doc.addImage(
    //   features.imageUrl,
    //   'PNG',
    //   26,
    //   25,
    //   166,
    //   81,
    //   'report2d',
    //   'SLOW'
    // );

    // doc.setFont('helvetica', 'normal');
    // doc.setFontSize(8);
    // doc.text(`Figure 6: Envelope 3D view `, 26, 109);
    // doc.setFontSize(10);

    //PAGE 7
    doc.addPage();
    addHeader(doc);

    doc.addImage(appendix, 'PNG', 26, 26, 163.9, 227.4, 'appendix1', 'SLOW');
    doc.addImage('/assets/images/pdf/north_symbol.png', 'PNG', 180.41, 242.4, 8, 8, 'north', 'SLOW');
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(`Appendix A: Map view showing applicable zoning layers.`, 26, 257.4);

    doc.setFontSize(10);
    const colors2: [number, number, number][] = [
      [110, 135, 100],
      [117, 235, 235],
      [248, 160, 220],
      [189, 148, 50],
      [176, 184, 248],
      [206, 231, 64],
    ];
    autoTable(doc, {
      styles: {
        fontSize: 7,
        lineWidth: 0.2,
        lineColor: 'black',
        cellPadding: 0.6,
      },
      headStyles: {
        lineWidth: {
          bottom: 0.2,
        },
        cellWidth: 0,
      },
      columnStyles: {
        0: { cellWidth: 5, fillColor: 'white' },
        1: { cellWidth: 48, fillColor: 'white' },
      },
      startY: 225.2, // Posición en el eje Y (vertical)
      margin: {
        left: 26,
      },
      // Posición en el eje X 2(horizontal) 166
      head: [['', '']],
      body: [
        ['', 'Special Purpose Districts'],
        ['', 'Mandatory Inclusionary Housing'],
        ['', 'Inclusionary Housing Designate Areas'],
        ['', 'Limited Height Districts'],
        ['', 'Historic District'],
        ['', 'Landmark Site'],

      ],
      theme: 'plain',
      didParseCell: (data) => {
        // Aplicar color a las celdas de la columna 0
        if (data.section === 'body' && data.column.index === 0) {
          const colorIndex = data.row.index % colors2.length;
          data.cell.styles.fillColor = colors2[colorIndex];
        }
      },
    });
    //PAGE 8
    doc.addPage();
    addHeader(doc);

    doc.addImage(appendix2, 'PNG', 26, 26, 163.9, 227.4, 'appendix2', 'SLOW');
    doc.addImage('/assets/images/pdf/north_symbol.png', 'PNG', 180.41, 242.4, 8, 8, 'north', 'SLOW');

    doc.setFontSize(8);
    doc.text(`Appendix B: Map view showing land use`, 26, 257.4);
    doc.setFontSize(10);

    doc.setFontSize(10);
    const colors3: [number, number, number][] = [
      [254, 255, 179],
      [242, 186, 90], //
      [166, 113, 38], //
      [240, 140, 81],
      [230, 64, 55],
      [212, 108, 244],
      [219, 191, 232],
      [97, 160, 209],
      [141, 208, 124],
      [185, 183, 182],
      [112, 107, 107],
      [230, 229, 229],
    ];
    autoTable(doc, {
      styles: {
        fontSize: 7,
        lineWidth: 0.2,
        lineColor: 'black',
        cellPadding: 0.6,
      },
      headStyles: {
        lineWidth: {
          bottom: 0.2,
        },
        cellWidth: 0,
      },
      columnStyles: {
        0: { cellWidth: 5, fillColor: 'white' },
        1: { cellWidth: 48, fillColor: 'white' },
      },
      startY: 200.95, // Posición en el eje Y (vertical)
      margin: {
        left: 26,
      },
      // Posición en el eje X 2(horizontal) 166
      head: [['', '']],
      body: [
        ['', 'One & Two Family Buildings'],
        ['', 'Multi-Family Walk-Up Buildings'],
        ['', 'Multi-Family Elevator Buildings'],
        ['', 'Mixed Residential & Commercial Buildings'],
        ['', 'Commercial & Office Buildings'],
        ['', 'Industrial & Manufacturing'],
        ['', 'Transportation & Utility'],
        ['', 'Public Facilities & Institutions'],
        ['', 'Open Space & Outdoor Recreation'],
        ['', 'Parking Facilities'],
        ['', 'Vacant Land'],
        ['', 'Other'],
      ],
      theme: 'plain',
      didParseCell: (data) => {
        // Aplicar color a las celdas de la columna 0
        if (data.section === 'body' && data.column.index === 0) {
          const colorIndex = data.row.index % colors3.length;
          data.cell.styles.fillColor = colors3[colorIndex];
        }
      },
    });



    // PAGE 9
    doc.addPage();
    addHeader(doc);

    doc.addImage(appendix3, 'PNG', 26, 26, 163.9, 227.4, 'appendix3', 'SLOW');
    doc.addImage('/assets/images/pdf/north_symbol.png', 'PNG', 180.41, 242.4, 8, 8, 'north', 'SLOW');

    doc.setFontSize(8);
    doc.text(`Appendix C: `, 26, 257.4);
    doc.setTextColor(0, 0, 255)
    doc.textWithLink('Preliminary Flood Maps 2015', 42, 257.4, { url: 'https://dcp.maps.arcgis.com/apps/webappviewer/index.html?id=1c37d271fba14163bbb520517153d6d5/' })
    doc.setTextColor(0, 0, 0)
    doc.setFontSize(10);

    const colors4: [number, number, number][] = [
      [65, 74, 180],
      [45, 156, 240], //
      [18, 205, 211], //
    ]
    autoTable(doc, {
      styles: {
        fontSize: 7,
        lineWidth: 0.2,
        lineColor: 'black',
        cellPadding: 0.6,
      },
      headStyles: {
        lineWidth: {
          bottom: 0.2,
        },
        cellWidth: 0,
      },
      columnStyles: {
        0: { cellWidth: 5, fillColor: 'white' },
        1: { cellWidth: 48, fillColor: 'white' },
      },
      startY: 237.2, // Posición en el eje Y (vertical)
      margin: {
        left: 26,
      },
      // Posición en el eje X 2(horizontal) 166
      head: [['', '']],
      body: [
        ['', 'V (1% floodplain) '],
        ['', 'A (1% floodplain) '],
        ['', 'Shaded X (0.2% floodplain)'],

      ],
      theme: 'plain',
      didParseCell: (data) => {
        // Aplicar color a las celdas de la columna 0
        if (data.section === 'body' && data.column.index === 0) {
          const colorIndex = data.row.index % colors4.length;
          data.cell.styles.fillColor = colors4[colorIndex];
        }
      },
    });

    // /assets/images/pdf/Slide1.png

    //PAGE10 
    doc.addPage();
    addHeader(doc);
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text('Disclaimer', 26, 31, { maxWidth: 163.9 });
    doc.setFont('helvetica', 'normal');
    const dataSource = `
The information provided by zlvas is intended solely for general informational purposes. While we are committed to maintaining the accuracy and reliability of our platform, we cannot guarantee the completeness or absolute accuracy of the data presented. Users are strongly advised to exercise their own due diligence and, when applicable, seek advice from qualified professionals for specific zoning related items.

The zoning envelope and 2D/3D information represented in this analysis is provided for illustrative purposes only and may not accurately reflect the precise dimensions or heights. It is essential to note that this information is based on available data and may contain inaccuracies or discrepancies. Therefore, we strongly advise corroborating this information against a thorough site survey conducted by a licensed surveyor.

In using our platform, you acknowledge and accept that zlvas shall not be held liable for any inaccuracies, errors, omissions, or damages that may arise from the utilization of our services. By accessing and using our platform, you agree that zlvas, its affiliates, and its employees shall not be responsible for any consequential or actual damages, including but not limited to financial losses, legal disputes, or any other adverse outcomes related to the use of the information provided.

Please be aware that any reliance on the data and information presented on zlvas is at your own risk. zlvas makes no warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose, regarding the quality, content, accuracy, or completeness of the information, text, graphics, links, or any other items contained within our platform. For the most current and official zoning information, you should refer to authoritative sources or consult with relevant government agencies.

Thank you for your understanding and cooperation in using zlvas responsibly and effectively.

zlvas team`;
    doc.text(dataSource, 26, 39, { maxWidth: 163.9 });

    doc.text(`zlvas © 2024 All rights reserved`, 26, 173, { maxWidth: 163.9 });
    doc.setTextColor(0, 0, 255);
    doc.text(`contact@zlvas.com`, 26, 176, { maxWidth: 163.9 });
    doc.setTextColor(0, 0, 0);

    addFooters(doc);

    // doc.save()
    // let blob: any = doc.output('blob');
    // window.open(URL.createObjectURL(blob));
    // URL.revokeObjectURL(URL.createObjectURL(blob));
    doc.save(pdfName !== '' ? `${pdfName}.pdf` : `${data_bbl.address.split(',')[0]}.pdf`
  );
  }
}
