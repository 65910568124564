import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Threebox } from 'threebox-plugin';
import { zlvas } from 'zlvas-mapbox';
import { useBulk } from 'use-bulk';
import { ZoningDataService } from '../../services/zoning-data.service';
import { EMPTY, Subscription, take, tap } from 'rxjs';
import { DarkModeService } from 'src/app/services/dark-mode.service';
import { ManageLayerService } from 'src/app/services/manage-layers.service';
import { Constants } from 'src/app/shared';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { ZoningAnalysisService } from 'src/app/services/zoning-analysis.service';
import { SetPaddingService } from 'src/app/services/set-padding.service';
import { GetLotService } from 'src/app/services/get-lot.service';
import { Router } from '@angular/router';
import { SetTagEnvelopeService } from 'src/app/services/set_tag_envelope.service';
import { BackendService } from 'src/app/services/backend.service';
import { LoadingService } from '../loading/services/loading.service';
import { SelectUseService } from 'src/app/services/select-use-info.service';
import { DisabledElementsExtrude } from 'src/app/services/disabled-elements-extrude.service';
import { AddressSearchService } from 'src/app/services/address-search.service';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewExportComponent } from '../view-export/view-export.component';
import { PdfReportComponent } from '../pdf-report/pdf-report.component';
import { StreetviewComponent } from '../streetview/streetview.component';
import { SnackbarTO } from '../snackbar/snackbar.to';
import { NavOptionExtent } from 'src/app/services/nav-option-extent.service';
import { SetAreaService } from 'src/app/services/set-area.service';
import { ModalViewService } from 'src/app/services/modal-view.service';
import { PrintService } from 'src/app/services/print.service';
import { UseTableComponent } from '../use-table/use-table.component';
import { PrintReportComponent } from '../print-report/print-report.component';
import { VisualizePdfFeatureService } from 'src/app/services/visualize-pdf-feature.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(PdfReportComponent) pdfReportComponent!: PdfReportComponent;

  enableOpacityOptions!: boolean;
  testPanel: boolean = environment.testPanel;

  config = {
    options: {
      accessToken:
        'pk.eyJ1Ijoiemx2YXMiLCJhIjoiY2xnaDJkOG1xMDJ3MzNlb2N2aW5jb2UxNSJ9.Y6N7kCprYNZnewEfZ15dDQ',
      container: 'map',
      style: 'mapbox://styles/mapbox/satellite-streets-v12',
      center: [-74.0432, 40.7264],
      zoom: 9.62,
      pitch: 0,
      bearing: 0,
      antialias: true,
      hash: false,
      cooperativeGestures: false,
    },
    sources: [
      {
        name: '3d',
        type: 'vector',
        url: 'mapbox://zlvas.25zsc09u',
      },
    ],
    light: {
      style: 'mapbox://styles/mapbox/light-v10',
      sky: {
        visibility: 'visible',
        halo: 'white',
        color: 'white',
      },
    },
    dark: {
      style: 'mapbox://styles/mapbox/dark-v11',
      sky: {
        visibility: 'visible',
        halo: 'black',
        color: 'black',
      },
    },
    manage_layer: {
      layers: [
        {
          html_name: 'Tax Lot (Landuse)',
          name: 'taxlot_landuse',
          type: 'vector',
          url: 'mapbox://zlvas.09f2dj8k',
          layer: {
            id: 'taxlot_landuse',
            type: 'fill',
            source: 'taxlot_landuse',
            'source-layer': 'taxlot-2eq758',
            layout: { visibility: 'none' },
            paint: {
              'fill-color': [
                'match',
                ['get', 'LANDUSE'],
                ['01'],
                'hsl(61, 100%, 85%)',
                ['02'],
                'hsl(38, 85%, 65%)',
                ['03'],
                'hsl(35, 63%, 40%)',
                ['04'],
                'hsl(22, 84%, 63%)',
                ['05'],
                'hsl(3, 78%, 56%)',
                ['06'],
                'hsl(286, 86%, 69%)',
                ['07'],
                'hsl(281, 48%, 83%)',
                ['08'],
                'hsl(206, 55%, 60%)',
                ['09'],
                'hsl(108, 47%, 65%)',
                ['10'],
                'hsl(20, 2%, 72%)',
                ['11'],
                'hsl(0, 2%, 43%)',
                'hsl(0, 2%, 90%)',
              ],
              'fill-opacity': 0.9,
            },
          },
        },
        {
          html_name: 'Tax Lot(Outline Landuse)',
          name: 'taxlot_landuse_o',
          type: 'vector',
          url: 'mapbox://zlvas.09f2dj8k',
          layer: {
            id: 'taxlot_landuse_o',
            type: 'line',
            source: 'taxlot_landuse_o',
            'source-layer': 'taxlot-2eq758',
            layout: { visibility: 'none' },
            paint: {
              'line-color': 'hsl(0, 0%, 57%)',
              'line-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                16,
                1,
              ],
            },
          },
        },
        {
          html_name: 'Zoning Districts(Parks)',
          name: 'zoning_districts_p',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_p',
            type: 'fill',
            source: 'zoning_districts_p',
            'source-layer': 'Zoning_Districts-bt7q50',

            layout: {
              visibility: 'visible',
            },
            paint: {
              'fill-color': [
                'match',
                ['get', 'ZONEDIST'],
                ['PARK', 'PLAYGROUND', 'BALL FIELD', 'PUBLIC PLACE'],
                'hsl(101, 91%, 69%)',
                'hsla(0, 0%, 0%, 0)',
              ],
              'fill-opacity': 0.5,
            },
            filter: [
              'in',
              ['get', 'ZONEDIST'],
              ['literal', ['PARK', 'BALL FIELD', 'PUBLIC PLACE', 'PLAYGROUND']],
            ],
          },
        },
        {
          html_name: 'Zoning Districts(Battery)',
          name: 'zoning_districts_b',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_b',
            type: 'fill',
            source: 'zoning_districts_b',
            'source-layer': 'Zoning_Districts-bt7q50',

            layout: {
              visibility: 'visible',
            },
            paint: {
              'fill-color': 'hsl(0, 1%, 83%)',
              'fill-opacity': 0.25,
            },
            filter: ['==', ['get', 'ZONEDIST'], 'BPC'],
          },
        },
        {
          html_name: 'Zoning Districts(Residence)',
          name: 'zoning_districts_r',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_r',
            type: 'fill',
            source: 'zoning_districts_r',
            'source-layer': 'Zoning_Districts-bt7q50',

            layout: {
              visibility: 'visible',
            },
            paint: {
              'fill-color': [
                'match',
                ['get', 'ZONEDIST'],
                [
                  'R6',
                  'R8',
                  'R9',
                  'R7-1',
                  'R7-2',
                  'R10',
                  'R8X',
                  'R7X',
                  'R8A',
                  'R7A',
                  'R8B',
                  'R1-2A',
                  'R6A',
                  'R7B',
                  'R6B',
                  'R7D',
                  'R10A',
                  'R7-3',
                  'R9-1',
                  'R9A',
                  'R9X',
                  'R10H',
                ],
                'hsl(40, 100%, 73%)',
                [
                  'C6-6.5',
                  'C4-4A',
                  'C6-2A',
                  'C1-6A',
                  'C4-3A',
                  'C5-2A',
                  'C4-2A',
                  'C4-5D',
                  'C4-4D',
                  'C3',
                  'C4-2F',
                  'C3A',
                  'C7',
                  'C4-4L',
                  'C4-1',
                  'C5-1',
                  'C4-2',
                  'C6-1',
                  'C5-2',
                  'C4-3',
                  'C6-2',
                  'C5-3',
                  'C4-4',
                  'C8-1',
                  'C6-3',
                  'C4-5',
                  'C8-2',
                  'C6-4',
                  'C2-8',
                  'C8-3',
                  'C4-7',
                  'C8-4',
                  'C6-6',
                  'C5-2.5',
                  'C4-5X',
                  'C6-5.5',
                  'C1-9',
                  'C1-9A',
                  'C1-7',
                  'C6-4.5',
                  'C6-2G',
                  'C2-6',
                  'C5-5',
                  'C4-6A',
                  'C1-8A',
                  'C2-7A',
                  'C6-7T',
                  'C5-P',
                  'C6-7',
                  'C1-7A',
                  'C6-4M',
                  'C6-5',
                  'C6-4X',
                  'C6-3A',
                  'C6-4A',
                  'C6-9',
                  'C6-1G',
                  'C1-6',
                  'C6-2M',
                  'C2-8A',
                  'C6-3X',
                  'C1-8',
                  'C4-5A',
                  'C4-6',
                  'C2-7',
                  'C2-6A',
                  'C1-8X',
                  'C5-1A',
                  'C6-1A',
                  'C5-4',
                  'C6-3D',
                ],
                'hsl(0, 82%, 65%)',
                [
                  'M1-1',
                  'M2-1',
                  'M1-2',
                  'M1-5/R9',
                  'M2-2',
                  'M1-3',
                  'M1-2/R6',
                  'M2-3',
                  'M1-4',
                  'M2-4',
                  'M1-5',
                  'M1-5/R7-3',
                  'M1-6/R10',
                  'M1-5/R7X',
                  'M1-2/R7-2',
                  'M1-4/R9A',
                  'M1-1/R6A',
                  'M1-4/R8A',
                  'M1-2/R6A',
                  'M1-4/R7A',
                  'M1-2/R6B',
                  'M1-1/R7D',
                  'M1-4/R6A',
                  'M1-2/R5B',
                  'M1-4/R6B',
                  'M1-4/R7D',
                  'M1-5/R10',
                  'M1-5/R9X ',
                  'M1-5/R7D ',
                  'M1-5/R9A ',
                  'M1-6',
                  'M1-5B',
                  'M1-5/R9X',
                  'M1-5/R7D',
                  'M1-5/R9A',
                  'M1-2/R8',
                  'M1-5/R9-1',
                  'M1-2/R8A',
                  'M1-6/R8X',
                  'M1-2D',
                  'M1-1/R5',
                  'M1-1D',
                  'M1-4/R7-2',
                  'M1-4/R7X',
                  'M1-5M',
                  'M1-6D',
                  'M1-4D',
                  'M1-3/R7X',
                  'M1-2/R5D',
                  'M1-4/R9',
                  'M1-4/R7-3',
                  'M1-5/R6A',
                  'M1-5/R7-2',
                  'M1-6/R9',
                  'M1-3/R8',
                  'M1-5/R8A',
                  'M1-1/R7-2',
                ],
                'hsl(312, 42%, 58%)',
                ['PUBLIC PLACE', 'BALL FIELD', 'PLAYGROUND', 'PARK'],
                'hsla(90, 59%, 56%, 0)',
                ['M3-1', 'M3-2', 'M3'],
                'hsl(312, 38%, 47%)',
                [
                  'R2',
                  'R3A',
                  'R4B',
                  'R2A',
                  'R5D',
                  'R4',
                  'R5',
                  'R1-2',
                  'R3-1',
                  'R4-1',
                  'R3-2',
                  'R3X',
                  'R2X',
                  'R5A',
                  'R4A',
                  'R5B',
                  'R1-1',
                ],
                'hsl(40, 94%, 81%)',
                ['BPC'],
                'hsl(0, 1%, 83%)',
                'hsla(0, 0%, 0%, 0)',
              ],
              'fill-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10,
                0.7,
                16,
                0.2,
                16.5,
                0,
              ],
              'fill-outline-color': 'hsla(0, 0%, 100%, 0)',
            },
            filter: ['==', ['index-of', 'R', ['get', 'ZONEDIST']], 0],
          },
        },
        {
          html_name: 'Zoning Districts(Commercial)',
          name: 'zoning_districts_c',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_c',
            type: 'fill',
            source: 'zoning_districts_c',
            'source-layer': 'Zoning_Districts-bt7q50',

            layout: {
              visibility: 'visible',
            },
            paint: {
              'fill-color': [
                'match',
                ['get', 'ZONEDIST'],
                [
                  'R6',
                  'R8',
                  'R9',
                  'R7-1',
                  'R7-2',
                  'R10',
                  'R8X',
                  'R7X',
                  'R8A',
                  'R7A',
                  'R8B',
                  'R1-2A',
                  'R6A',
                  'R7B',
                  'R6B',
                  'R7D',
                  'R10A',
                  'R7-3',
                  'R9-1',
                  'R9A',
                  'R9X',
                  'R10H',
                ],
                'hsl(40, 100%, 73%)',
                [
                  'C6-6.5',
                  'C4-4A',
                  'C6-2A',
                  'C1-6A',
                  'C4-3A',
                  'C5-2A',
                  'C4-2A',
                  'C4-5D',
                  'C4-4D',
                  'C3',
                  'C4-2F',
                  'C3A',
                  'C7',
                  'C4-4L',
                  'C4-1',
                  'C5-1',
                  'C4-2',
                  'C6-1',
                  'C5-2',
                  'C4-3',
                  'C6-2',
                  'C5-3',
                  'C4-4',
                  'C8-1',
                  'C6-3',
                  'C4-5',
                  'C8-2',
                  'C6-4',
                  'C2-8',
                  'C8-3',
                  'C4-7',
                  'C8-4',
                  'C6-6',
                  'C5-2.5',
                  'C4-5X',
                  'C6-5.5',
                  'C1-9',
                  'C1-9A',
                  'C1-7',
                  'C6-4.5',
                  'C6-2G',
                  'C2-6',
                  'C5-5',
                  'C4-6A',
                  'C1-8A',
                  'C2-7A',
                  'C6-7T',
                  'C5-P',
                  'C6-7',
                  'C1-7A',
                  'C6-4M',
                  'C6-5',
                  'C6-4X',
                  'C6-3A',
                  'C6-4A',
                  'C6-9',
                  'C6-1G',
                  'C1-6',
                  'C6-2M',
                  'C2-8A',
                  'C6-3X',
                  'C1-8',
                  'C4-5A',
                  'C4-6',
                  'C2-7',
                  'C2-6A',
                  'C1-8X',
                  'C5-1A',
                  'C6-1A',
                  'C5-4',
                  'C6-3D',
                ],
                'hsl(0, 82%, 65%)',
                [
                  'M1-1',
                  'M2-1',
                  'M1-2',
                  'M1-5/R9',
                  'M2-2',
                  'M1-3',
                  'M1-2/R6',
                  'M2-3',
                  'M1-4',
                  'M2-4',
                  'M1-5',
                  'M1-5/R7-3',
                  'M1-6/R10',
                  'M1-5/R7X',
                  'M1-2/R7-2',
                  'M1-4/R9A',
                  'M1-1/R6A',
                  'M1-4/R8A',
                  'M1-2/R6A',
                  'M1-4/R7A',
                  'M1-2/R6B',
                  'M1-1/R7D',
                  'M1-4/R6A',
                  'M1-2/R5B',
                  'M1-4/R6B',
                  'M1-4/R7D',
                  'M1-5/R10',
                  'M1-5/R9X ',
                  'M1-5/R7D ',
                  'M1-5/R9A ',
                  'M1-6',
                  'M1-5B',
                  'M1-5/R9X',
                  'M1-5/R7D',
                  'M1-5/R9A',
                  'M1-2/R8',
                  'M1-5/R9-1',
                  'M1-2/R8A',
                  'M1-6/R8X',
                  'M1-2D',
                  'M1-1/R5',
                  'M1-1D',
                  'M1-4/R7-2',
                  'M1-4/R7X',
                  'M1-5M',
                  'M1-6D',
                  'M1-4D',
                  'M1-3/R7X',
                  'M1-2/R5D',
                  'M1-4/R9',
                  'M1-4/R7-3',
                  'M1-5/R6A',
                  'M1-5/R7-2',
                  'M1-6/R9',
                  'M1-3/R8',
                  'M1-5/R8A',
                  'M1-1/R7-2',
                ],
                'hsl(312, 42%, 58%)',
                ['PUBLIC PLACE', 'BALL FIELD', 'PLAYGROUND', 'PARK'],
                'hsla(90, 59%, 56%, 0)',
                ['M3-1', 'M3-2', 'M3'],
                'hsl(312, 38%, 47%)',
                [
                  'R2',
                  'R3A',
                  'R4B',
                  'R2A',
                  'R5D',
                  'R4',
                  'R5',
                  'R1-2',
                  'R3-1',
                  'R4-1',
                  'R3-2',
                  'R3X',
                  'R2X',
                  'R5A',
                  'R4A',
                  'R5B',
                  'R1-1',
                ],
                'hsl(40, 94%, 81%)',
                ['BPC'],
                'hsl(0, 1%, 83%)',
                'hsla(0, 0%, 0%, 0)',
              ],
              'fill-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10,
                0.7,
                16,
                0.2,
                16.5,
                0,
              ],
              'fill-outline-color': 'hsla(0, 0%, 100%, 0)',
            },
            filter: ['==', ['index-of', 'C', ['get', 'ZONEDIST']], 0],
          },
        },
        {
          html_name: 'Zoning Districts(Manufacturing)',
          name: 'zoning_districts_m',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_m',
            type: 'fill',
            source: 'zoning_districts_m',
            'source-layer': 'Zoning_Districts-bt7q50',

            layout: {
              visibility: 'visible',
            },
            paint: {
              'fill-color': [
                'match',
                ['get', 'ZONEDIST'],
                [
                  'R6',
                  'R8',
                  'R9',
                  'R7-1',
                  'R7-2',
                  'R10',
                  'R8X',
                  'R7X',
                  'R8A',
                  'R7A',
                  'R8B',
                  'R1-2A',
                  'R6A',
                  'R7B',
                  'R6B',
                  'R7D',
                  'R10A',
                  'R7-3',
                  'R9-1',
                  'R9A',
                  'R9X',
                  'R10H',
                ],
                'hsl(40, 100%, 73%)',
                [
                  'C6-6.5',
                  'C4-4A',
                  'C6-2A',
                  'C1-6A',
                  'C4-3A',
                  'C5-2A',
                  'C4-2A',
                  'C4-5D',
                  'C4-4D',
                  'C3',
                  'C4-2F',
                  'C3A',
                  'C7',
                  'C4-4L',
                  'C4-1',
                  'C5-1',
                  'C4-2',
                  'C6-1',
                  'C5-2',
                  'C4-3',
                  'C6-2',
                  'C5-3',
                  'C4-4',
                  'C8-1',
                  'C6-3',
                  'C4-5',
                  'C8-2',
                  'C6-4',
                  'C2-8',
                  'C8-3',
                  'C4-7',
                  'C8-4',
                  'C6-6',
                  'C5-2.5',
                  'C4-5X',
                  'C6-5.5',
                  'C1-9',
                  'C1-9A',
                  'C1-7',
                  'C6-4.5',
                  'C6-2G',
                  'C2-6',
                  'C5-5',
                  'C4-6A',
                  'C1-8A',
                  'C2-7A',
                  'C6-7T',
                  'C5-P',
                  'C6-7',
                  'C1-7A',
                  'C6-4M',
                  'C6-5',
                  'C6-4X',
                  'C6-3A',
                  'C6-4A',
                  'C6-9',
                  'C6-1G',
                  'C1-6',
                  'C6-2M',
                  'C2-8A',
                  'C6-3X',
                  'C1-8',
                  'C4-5A',
                  'C4-6',
                  'C2-7',
                  'C2-6A',
                  'C1-8X',
                  'C5-1A',
                  'C6-1A',
                  'C5-4',
                  'C6-3D',
                ],
                'hsl(0, 82%, 65%)',
                [
                  'M1-1',
                  'M2-1',
                  'M1-2',
                  'M1-5/R9',
                  'M2-2',
                  'M1-3',
                  'M1-2/R6',
                  'M2-3',
                  'M1-4',
                  'M2-4',
                  'M1-5',
                  'M1-5/R7-3',
                  'M1-6/R10',
                  'M1-5/R7X',
                  'M1-2/R7-2',
                  'M1-4/R9A',
                  'M1-1/R6A',
                  'M1-4/R8A',
                  'M1-2/R6A',
                  'M1-4/R7A',
                  'M1-2/R6B',
                  'M1-1/R7D',
                  'M1-4/R6A',
                  'M1-2/R5B',
                  'M1-4/R6B',
                  'M1-4/R7D',
                  'M1-5/R10',
                  'M1-5/R9X ',
                  'M1-5/R7D ',
                  'M1-5/R9A ',
                  'M1-6',
                  'M1-5B',
                  'M1-5/R9X',
                  'M1-5/R7D',
                  'M1-5/R9A',
                  'M1-2/R8',
                  'M1-5/R9-1',
                  'M1-2/R8A',
                  'M1-6/R8X',
                  'M1-2D',
                  'M1-1/R5',
                  'M1-1D',
                  'M1-4/R7-2',
                  'M1-4/R7X',
                  'M1-5M',
                  'M1-6D',
                  'M1-4D',
                  'M1-3/R7X',
                  'M1-2/R5D',
                  'M1-4/R9',
                  'M1-4/R7-3',
                  'M1-5/R6A',
                  'M1-5/R7-2',
                  'M1-6/R9',
                  'M1-3/R8',
                  'M1-5/R8A',
                  'M1-1/R7-2',
                ],
                'hsl(312, 42%, 58%)',
                ['PUBLIC PLACE', 'BALL FIELD', 'PLAYGROUND', 'PARK'],
                'hsla(90, 59%, 56%, 0)',
                ['M3-1', 'M3-2', 'M3'],
                'hsl(312, 38%, 47%)',
                [
                  'R2',
                  'R3A',
                  'R4B',
                  'R2A',
                  'R5D',
                  'R4',
                  'R5',
                  'R1-2',
                  'R3-1',
                  'R4-1',
                  'R3-2',
                  'R3X',
                  'R2X',
                  'R5A',
                  'R4A',
                  'R5B',
                  'R1-1',
                ],
                'hsl(40, 94%, 81%)',
                ['BPC'],
                'hsl(0, 1%, 83%)',
                'hsla(0, 0%, 0%, 0)',
              ],
              'fill-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10,
                0.7,
                16,
                0.2,
                16.5,
                0,
              ],
              'fill-outline-color': 'hsla(0, 0%, 100%, 0)',
            },
            filter: ['==', ['index-of', 'M', ['get', 'ZONEDIST']], 0],
          },
        },
        {
          html_name: 'Special Purpose District',
          name: 'Special_Purpose_District',
          type: 'vector',
          url: 'mapbox://zlvas.ay3m33tv',
          layer: {
            id: 'Special_Purpose_District',
            type: 'fill',
            source: 'Special_Purpose_District',
            'source-layer': 'Special_Purpose_District-5s1rri',
            layout: {
              visibility: 'none',
            },
            paint: {
              'fill-color': 'hsl(102, 13%, 35%)',
              'fill-opacity': 0.4,
              'fill-outline-color': 'hsla(0, 0%, 100%, 0)',
            },
          },
        },
        {
          html_name: 'Special Purpose Subdistrict',
          name: 'Special_Purpose_Subdistrict',
          type: 'vector',
          url: 'mapbox://zlvas.c4xqa2rb',
          layer: {
            id: 'Special_Purpose_Subdistrict',
            type: 'fill',
            source: 'Special_Purpose_Subdistrict',
            'source-layer': 'Special_Purpose_Subdistrict-dit7bw',
            layout: {
              visibility: 'none',
            },
            paint: { 'fill-color': 'hsl(102, 15%, 47%)', 'fill-opacity': 0.4 },
          },
        },
        {
          html_name: 'IHDA',
          name: 'IHDA',
          type: 'vector',
          url: 'mapbox://zlvas.0gqhj9ww',
          layer: {
            id: 'IHDA',
            type: 'fill',
            source: 'IHDA',
            'source-layer': 'Inclusionary_Housing_Designat-61hori',
            layout: { visibility: 'none' },
            paint: { 'fill-color': '#f679ce', 'fill-opacity': 0.3 },
          },
        },
        {
          html_name: 'IHDA(Outline)',
          name: 'IHDA_o',
          type: 'vector',
          url: 'mapbox://zlvas.0gqhj9ww',
          layer: {
            id: 'IHDA_o',
            type: 'line',
            source: 'IHDA_o',
            'source-layer': 'Inclusionary_Housing_Designat-61hori',
            layout: { 'line-cap': 'round', visibility: 'none' },
            paint: {
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                0.1,
                17,
                5,
              ],
              'line-dasharray': [
                'step',
                ['zoom'],
                ['literal', [0.1, 0.3]],
                12,
                ['literal', [1, 3]],
              ],
              'line-color': 'hsl(319, 86%, 80%)',
            },
          },
        },
        {
          html_name: 'MIH',
          name: 'MIH',
          type: 'vector',
          url: 'mapbox://zlvas.11llq5bd',
          layer: {
            id: 'MIH',
            type: 'fill',
            source: 'MIH',
            'source-layer': 'Mandatory_Inclusionary_Housin-1ef841',
            layout: {
              visibility: 'none',
            },
            paint: { 'fill-color': 'hsl(180, 57%, 57%)', 'fill-opacity': 0.3 },
          },
        },
        {
          html_name: 'MIH(Outline)',
          name: 'MIH_o',
          type: 'vector',
          url: 'mapbox://zlvas.11llq5bd',
          layer: {
            id: 'MIH_o',
            type: 'line',
            source: 'MIH_o',
            'source-layer': 'Mandatory_Inclusionary_Housin-1ef841',
            layout: {
              'line-cap': 'round',
              visibility: 'none',
            },
            paint: {
              'line-color': 'hsl(180, 75%, 69%)',
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                0.1,
                17,
                5,
              ],
              'line-dasharray': [
                'step',
                ['zoom'],
                ['literal', [0.1, 0.3]],
                12,
                ['literal', [1, 3]],
              ],
            },
          },
        },
        {
          html_name: 'Limited Height Disctricts',
          name: 'Limited_Height_Districts',
          type: 'vector',
          url: 'mapbox://zlvas.6dudr1uu',
          layer: {
            id: 'Limited_Height_Districts',
            type: 'fill',
            source: 'Limited_Height_Districts',
            'source-layer': 'Limited_Height_Districts-3i64wv',
            layout: {
              visibility: 'none',
            },
            paint: { 'fill-color': 'hsl(43, 73%, 34%)', 'fill-opacity': 0.5 },
          },
        },
        {
          html_name: 'Limited Height Disctricts(Outline)',
          name: 'Limited_Height_Districts_o',
          type: 'vector',
          url: 'mapbox://zlvas.6dudr1uu',
          layer: {
            id: 'Limited_Height_Districts_o',
            type: 'line',
            source: 'Limited_Height_Districts_o',
            'source-layer': 'Limited_Height_Districts-3i64wv',
            layout: { 'line-cap': 'round', visibility: 'none' },
            paint: {
              'line-color': 'hsl(42, 58%, 47%)',
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                0.1,
                17,
                5,
              ],
              'line-dasharray': [
                'step',
                ['zoom'],
                ['literal', [0.1, 0.3]],
                12,
                ['literal', [1, 3]],
              ],
            },
          },
        },
        {
          html_name: 'Historict District',
          name: 'Historict_District',
          type: 'vector',
          url: 'mapbox://zlvas.7icesbej',
          layer: {
            id: 'Historict_District',
            type: 'fill',
            source: 'Historict_District',
            'source-layer': 'Historic_Districts-c5us3r',
            layout: {
              visibility: 'none',
            },
            paint: { 'fill-color': 'hsl(233, 91%, 66%)', 'fill-opacity': 0.5 },
          },
        },
        {
          html_name: 'Historict District(Outline)',
          name: 'Historict_District_o',
          type: 'vector',
          url: 'mapbox://zlvas.7icesbej',
          layer: {
            id: 'Historict_District_o',
            type: 'line',
            source: 'Historict_District_o',
            'source-layer': 'Historic_Districts-c5us3r',
            layout: { 'line-cap': 'round', visibility: 'none' },
            paint: {
              'line-color': 'hsl(233, 83%, 83%)',
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                0.1,
                17,
                5,
              ],
              'line-dasharray': [
                'step',
                ['zoom'],
                ['literal', [0.1, 0.3]],
                12,
                ['literal', [1, 3]],
              ],
            },
          },
        },
        {
          html_name: 'Effective Flood Maps 2007(V)',
          name: 'Effective_Flood_v',
          type: 'vector',
          url: 'mapbox://zlvas.84vpu3e1',
          layer: {
            id: 'Effective_Flood_v',
            type: 'fill',
            source: 'Effective_Flood_v',
            'source-layer': 'Effective_Flood_Insurance_Rat-6c6ko5',
            layout: {
              visibility: 'none',
            },
            minzoom: 12,
            paint: {
              'fill-color': [
                'case',
                [
                  'match',
                  ['get', 'ZONE_SUBTY'],
                  ['AREA OF MINIMAL FLOOD HAZARD'],
                  true,
                  false,
                ],
                'hsla(0, 0%, 0%, 0)',
                ['match', ['get', 'FLD_ZONE'], ['X'], true, false],
                'hsla(182, 84%, 45%, 0.49)',
                ['match', ['get', 'FLD_ZONE'], ['AE', 'A'], true, false],
                'hsla(206, 87%, 56%, 0.5)',
                ['match', ['get', 'FLD_ZONE'], ['VE'], true, false],
                'hsla(235, 47%, 48%, 0.5)',
                ['match', ['get', 'FLD_ZONE'], ['OPEN WATER'], true, false],
                'hsla(0, 0%, 0%, 0)',
                'hsla(0, 0%, 0%, 0.47)',
              ],
            },
            filter: ['==', ['index-of', 'V', ['get', 'FLD_ZONE']], 0],
          },
        },
        {
          html_name: 'Effective Flood Maps 2007(A)',
          name: 'Effective_Flood_a',
          type: 'vector',
          url: 'mapbox://zlvas.84vpu3e1',
          layer: {
            id: 'Effective_Flood_a',
            type: 'fill',
            source: 'Effective_Flood_a',
            'source-layer': 'Effective_Flood_Insurance_Rat-6c6ko5',
            layout: {
              visibility: 'none',
            },
            minzoom: 12,
            paint: {
              'fill-color': [
                'case',
                [
                  'match',
                  ['get', 'ZONE_SUBTY'],
                  ['AREA OF MINIMAL FLOOD HAZARD'],
                  true,
                  false,
                ],
                'hsla(0, 0%, 0%, 0)',
                ['match', ['get', 'FLD_ZONE'], ['X'], true, false],
                'hsla(182, 84%, 45%, 0.49)',
                ['match', ['get', 'FLD_ZONE'], ['AE', 'A'], true, false],
                'hsla(206, 87%, 56%, 0.5)',
                ['match', ['get', 'FLD_ZONE'], ['VE'], true, false],
                'hsla(235, 47%, 48%, 0.5)',
                ['match', ['get', 'FLD_ZONE'], ['OPEN WATER'], true, false],
                'hsla(0, 0%, 0%, 0)',
                'hsla(0, 0%, 0%, 0.47)',
              ],
            },
            filter: ['==', ['index-of', 'A', ['get', 'FLD_ZONE']], 0],
          },
        },
        {
          html_name: 'Effective Flood Maps 2007(X)',
          name: 'Effective_Flood_x',
          type: 'vector',
          url: 'mapbox://zlvas.84vpu3e1',
          layer: {
            id: 'Effective_Flood_x',
            type: 'fill',
            source: 'Effective_Flood_x',
            'source-layer': 'Effective_Flood_Insurance_Rat-6c6ko5',
            layout: {
              visibility: 'none',
            },
            minzoom: 12,
            paint: {
              'fill-color': [
                'case',
                [
                  'match',
                  ['get', 'ZONE_SUBTY'],
                  ['AREA OF MINIMAL FLOOD HAZARD'],
                  true,
                  false,
                ],
                'hsla(0, 0%, 0%, 0)',
                ['match', ['get', 'FLD_ZONE'], ['X'], true, false],
                'hsla(182, 84%, 45%, 0.49)',
                ['match', ['get', 'FLD_ZONE'], ['AE', 'A'], true, false],
                'hsla(206, 87%, 56%, 0.5)',
                ['match', ['get', 'FLD_ZONE'], ['VE'], true, false],
                'hsla(235, 47%, 48%, 0.5)',
                ['match', ['get', 'FLD_ZONE'], ['OPEN WATER'], true, false],
                'hsla(0, 0%, 0%, 0)',
                'hsla(0, 0%, 0%, 0.47)',
              ],
            },
            filter: ['==', ['index-of', 'X', ['get', 'FLD_ZONE']], 0],
          },
        },
        {
          html_name: 'Preliminary Flood Maps 2015(V)',
          name: 'Preliminary_Flood_v',
          type: 'vector',
          url: 'mapbox://zlvas.8h87zn3d',
          layer: {
            id: 'Preliminary_Flood_v',
            type: 'fill',
            source: 'Preliminary_Flood_v',
            'source-layer': 'Preliminary_Flood_Insurance_R-23gczw',
            layout: {
              visibility: 'none',
            },
            minzoom: 12,
            paint: {
              'fill-color': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                [
                  'match',
                  ['get', 'FLD_ZONE'],
                  ['AE', 'AO', 'A'],
                  'hsla(206, 87%, 56%, 0.5)',
                  ['VE'],
                  'hsla(235, 47%, 48%, 0.5)',
                  ['0.2 PCT ANNUAL CHANCE FLOOD HAZARD'],
                  'hsla(182, 84%, 45%, 0.49)',
                  'hsla(0, 0%, 0%, 0)',
                ],
                22,
                [
                  'match',
                  ['get', 'FLD_ZONE'],
                  ['AE', 'AO', 'A'],
                  'hsla(206, 87%, 56%, 0.5)',
                  ['VE'],
                  'hsla(235, 47%, 48%, 0.5)',
                  ['0.2 PCT ANNUAL CHANCE FLOOD HAZARD'],
                  'hsla(182, 84%, 45%, 0.49)',
                  'hsla(0, 0%, 0%, 0)',
                ],
              ],
            },
            filter: ['==', ['index-of', 'V', ['get', 'FLD_ZONE']], 0],
          },
        },
        {
          html_name: 'Preliminary Flood Maps 2015(A)',
          name: 'Preliminary_Flood_a',
          type: 'vector',
          url: 'mapbox://zlvas.8h87zn3d',
          layer: {
            id: 'Preliminary_Flood_a',
            type: 'fill',
            source: 'Preliminary_Flood_a',
            'source-layer': 'Preliminary_Flood_Insurance_R-23gczw',
            layout: {
              visibility: 'none',
            },
            minzoom: 12,
            paint: {
              'fill-color': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                [
                  'match',
                  ['get', 'FLD_ZONE'],
                  ['AE', 'AO', 'A'],
                  'hsla(206, 87%, 56%, 0.5)',
                  ['VE'],
                  'hsla(235, 47%, 48%, 0.5)',
                  ['0.2 PCT ANNUAL CHANCE FLOOD HAZARD'],
                  'hsla(182, 84%, 45%, 0.49)',
                  'hsla(0, 0%, 0%, 0)',
                ],
                22,
                [
                  'match',
                  ['get', 'FLD_ZONE'],
                  ['AE', 'AO', 'A'],
                  'hsla(206, 87%, 56%, 0.5)',
                  ['VE'],
                  'hsla(235, 47%, 48%, 0.5)',
                  ['0.2 PCT ANNUAL CHANCE FLOOD HAZARD'],
                  'hsla(182, 84%, 45%, 0.49)',
                  'hsla(0, 0%, 0%, 0)',
                ],
              ],
            },
            filter: ['==', ['index-of', 'A', ['get', 'FLD_ZONE']], 0],
          },
        },
        {
          html_name: 'Preliminary Flood Maps 2015(X)',
          name: 'Preliminary_Flood_x',
          type: 'vector',
          url: 'mapbox://zlvas.8h87zn3d',
          layer: {
            id: 'Preliminary_Flood_x',
            type: 'fill',
            source: 'Preliminary_Flood_x',
            'source-layer': 'Preliminary_Flood_Insurance_R-23gczw',
            layout: {
              visibility: 'none',
            },
            minzoom: 12,
            paint: {
              'fill-color': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                [
                  'match',
                  ['get', 'FLD_ZONE'],
                  ['AE', 'AO', 'A'],
                  'hsla(206, 87%, 56%, 0.5)',
                  ['VE'],
                  'hsla(235, 47%, 48%, 0.5)',
                  ['0.2 PCT ANNUAL CHANCE FLOOD HAZARD'],
                  'hsla(182, 84%, 45%, 0.49)',
                  'hsla(0, 0%, 0%, 0)',
                ],
                22,
                [
                  'match',
                  ['get', 'FLD_ZONE'],
                  ['AE', 'AO', 'A'],
                  'hsla(206, 87%, 56%, 0.5)',
                  ['VE'],
                  'hsla(235, 47%, 48%, 0.5)',
                  ['0.2 PCT ANNUAL CHANCE FLOOD HAZARD'],
                  'hsla(182, 84%, 45%, 0.49)',
                  'hsla(0, 0%, 0%, 0)',
                ],
              ],
            },
            filter: ['==', ['index-of', '0.2', ['get', 'FLD_ZONE']], 0],
          },
        },
        {
          html_name: 'Topography',
          name: 'contour_4326',
          type: 'vector',
          url: 'mapbox://zlvas.apa07f9n',
          layer: {
            id: 'contour_4326',
            type: 'line',
            source: 'contour_4326',
            'source-layer': 'contour_4326',
            layout: {
              visibility: 'none',
            },
            paint: {
              'line-opacity': 0.5,
              'line-width': 0.5,
              'line-color': '#b3b3b3',
            },
          },
        },
        {
          html_name: 'Zoning Districts(Parks Outline)',
          name: 'zoning_districts_po',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_po',
            type: 'line',
            source: 'zoning_districts_po',
            'source-layer': 'Zoning_Districts-bt7q50',

            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                'hsl(0, 0%, 100%)',
                22,
                'hsl(0, 0%, 100%)',
              ],
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10.5,
                0,
                15,
                0.5,
                22,
                5,
              ],
              'line-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10,
                0,
                14,
                1,
              ],
            },
            filter: [
              'in',
              ['get', 'ZONEDIST'],
              ['literal', ['PARK', 'BALL FIELD', 'PUBLIC PLACE', 'PLAYGROUND']],
            ],
          },
        },
        {
          html_name: 'Zoning Districts(Battery Outline)',
          name: 'zoning_districts_bo',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_bo',
            type: 'line',
            source: 'zoning_districts_bo',
            'source-layer': 'Zoning_Districts-bt7q50',

            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                'hsl(0, 0%, 100%)',
                22,
                'hsl(0, 0%, 100%)',
              ],
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10.5,
                0,
                15,
                0.5,
                22,
                5,
              ],
              'line-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10,
                0,
                14,
                1,
              ],
            },
            filter: ['==', ['get', 'ZONEDIST'], 'BPC'],
          },
        },
        {
          html_name: 'Zoning Districts(Residence Outline)',
          name: 'zoning_districts_ro',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_ro',
            type: 'line',
            source: 'zoning_districts_ro',
            'source-layer': 'Zoning_Districts-bt7q50',

            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                'hsl(0, 0%, 100%)',
                22,
                'hsl(0, 0%, 100%)',
              ],
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10.5,
                0,
                15,
                0.5,
                22,
                5,
              ],
              'line-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10,
                0,
                14,
                1,
              ],
            },
            filter: ['==', ['index-of', 'R', ['get', 'ZONEDIST']], 0],
          },
        },
        {
          html_name: 'Zoning Districts(Manufacturing Outline)',
          name: 'zoning_districts_mo',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_mo',
            type: 'line',
            source: 'zoning_districts_mo',
            'source-layer': 'Zoning_Districts-bt7q50',

            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                'hsl(0, 0%, 100%)',
                22,
                'hsl(0, 0%, 100%)',
              ],
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10.5,
                0,
                15,
                0.5,
                22,
                5,
              ],
              'line-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10,
                0,
                14,
                1,
              ],
            },
            filter: ['==', ['index-of', 'M', ['get', 'ZONEDIST']], 0],
          },
        },
        {
          html_name: 'Zoning Districts(Commercial Outline)',
          name: 'zoning_districts_co',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_co',
            type: 'line',
            source: 'zoning_districts_co',
            'source-layer': 'Zoning_Districts-bt7q50',

            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                'hsl(0, 0%, 100%)',
                22,
                'hsl(0, 0%, 100%)',
              ],
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10.5,
                0,
                15,
                0.5,
                22,
                5,
              ],
              'line-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10,
                0,
                14,
                1,
              ],
            },
            filter: ['==', ['index-of', 'C', ['get', 'ZONEDIST']], 0],
          },
        },
        {
          html_name: 'Commercial Overlay(C1_2-C1_5)',
          name: 'Commercial_Overlay_c1_1',
          type: 'vector',
          url: 'mapbox://zlvas.d66u3nph',
          layer: {
            id: 'Commercial_Overlay_c1_1',
            type: 'line',
            source: 'Commercial_Overlay_c1_1',
            'source-layer': 'Commercial_Overlay-c2emjy',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color': '#d76565',
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10,
                0.1,
                16,
                2,
                17,
                3,
              ],
            },
            filter: [
              'in',
              ['get', 'OVERLAY'],
              ['literal', ['C1-1', 'C1-2', 'C1-3', 'C1-4', 'C1-5']],
            ],
          },
        },
        {
          html_name: 'Commercial Overlay(C2_2-C2_5)',
          name: 'Commercial_Overlay_c2_1',
          type: 'vector',
          url: 'mapbox://zlvas.d66u3nph',
          layer: {
            id: 'Commercial_Overlay_c2_1',
            type: 'line',
            source: 'Commercial_Overlay_c2_1',
            'source-layer': 'Commercial_Overlay-c2emjy',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color': '#d76565',
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10,
                0.1,
                16,
                2,
                17,
                3,
              ],
            },
            filter: [
              'in',
              ['get', 'OVERLAY'],
              ['literal', ['C2-1', 'C2-2', 'C2-3', 'C2-4', 'C2-5']],
            ],
          },
        },
        {
          html_name: 'Special Purpose District(Outline)',
          name: 'Special_Purpose_District_outline',
          type: 'vector',
          url: 'mapbox://zlvas.ay3m33tv',
          layer: {
            id: 'Special_Purpose_District_outline',
            type: 'line',
            source: 'Special_Purpose_District_outline',
            'source-layer': 'Special_Purpose_District-5s1rri',
            layout: {
              'line-cap': 'round',
              visibility: 'none',
            },
            paint: {
              'line-color': 'hsl(102, 15%, 46%)',
              'line-dasharray': [
                'step',
                ['zoom'],
                ['literal', [0.1, 0.3]],
                12,
                ['literal', [1, 3]],
              ],
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                0.1,
                17,
                5,
              ],
            },
          },
        },
        {
          html_name: 'Special Purpose Subdistrict(Outline)',
          name: 'Special_Purpose_Subdistrict_outline',
          type: 'vector',
          url: 'mapbox://zlvas.c4xqa2rb',
          layer: {
            id: 'Special_Purpose_Subdistrict_outline',
            type: 'line',
            source: 'Special_Purpose_Subdistrict_outline',
            'source-layer': 'Special_Purpose_Subdistrict-dit7bw',
            layout: {
              'line-cap': 'round',
              visibility: 'none',
            },
            paint: {
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                0.1,
                17,
                5,
              ],
              'line-dasharray': [
                'step',
                ['zoom'],
                ['literal', [0.1, 0.3]],
                12,
                ['literal', [1, 3]],
              ],
              'line-color': 'hsl(102, 15%, 47%)',
            },
          },
        },
        {
          html_name: 'E Designation',
          name: 'E_Designation-1memwl',
          type: 'vector',
          url: 'mapbox://zlvas.0dbtuslk',
          layer: {
            id: 'E_Designation-1memwl',
            type: 'circle',
            source: 'E_Designation-1memwl',
            'source-layer': 'E_Designation-1memwl',
            layout: {
              visibility: 'none',
            },
            paint: {
              'circle-color': 'hsl(0, 0%, 100%)',
              'circle-stroke-width': 1,
              'circle-stroke-color': '#1e49f6',
              'circle-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                2,
                22,
                8,
              ],
            },
          },
        },
        {
          html_name: 'lots(Outline)',
          name: 'lot_outline',
          type: 'vector',
          url: 'mapbox://zlvas.09f2dj8k',
          layer: {
            id: 'lot_outline',
            type: 'line',
            source: 'lot_outline',
            'source-layer': 'taxlot-2eq758',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'select'], false],
                '#F0D459',
                ['boolean', ['feature-state', 'hover'], false],
                '#F0D459',
                'hsl(0, 0%, 57%)',
              ],
              'line-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                16,
                1,
              ],
              'line-width': [
                'case',
                ['boolean', ['feature-state', 'select'], false],
                4,
                ['boolean', ['feature-state', 'hover'], false],
                2,
                1,
              ],
            },
          },
        },
        {
          html_name: 'lots(select)',
          name: 'lot_select',
          type: 'vector',
          url: 'mapbox://zlvas.09f2dj8k',
          layer: {
            id: 'lot_select',
            type: 'line',
            source: 'lot_select',
            'source-layer': 'taxlot-2eq758',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color': '#F0D459',
              'line-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                16,
                1,
              ],
              'line-width': [
                'case',
                ['boolean', ['feature-state', 'select'], false],
                4,
                0,
              ],
            },
          },
        },
        {
          html_name: 'Landmark Sites',
          name: 'Landmark_Sites-8qhywx',
          type: 'vector',
          url: 'mapbox://zlvas.ajxm9pow',
          layer: {
            id: 'Landmark_Sites-8qhywx',
            type: 'fill',
            source: 'Landmark_Sites-8qhywx',
            'source-layer': 'Landmark_Sites-8qhywx',
            layout: {
              visibility: 'none',
            },
            paint: { 'fill-color': 'hsl(69, 78%, 58%)', 'fill-opacity': 0.7 },
          },
        },
        {
          html_name: 'Community District',
          name: 'community_districts',
          type: 'vector',
          url: 'mapbox://zlvas.4gxhdsd5',
          layer: {
            id: 'community_districts',
            type: 'line',
            source: 'community_districts',
            'source-layer': 'Community_Districts-11qfco',
            layout: {
              visibility: 'none',
            },
            paint: {
              'line-width': ['interpolate', ['linear'], ['zoom'], 10, 2, 22, 8],
              'line-color': '#f0ceb7',
            },
          },
        },
        {
          html_name: 'Boroughs',
          name: 'borough_boundaries',
          type: 'vector',
          url: 'mapbox://zlvas.0zhzqqj4',
          layer: {
            id: 'borough_boundaries',
            type: 'line',
            source: 'borough_boundaries',
            'source-layer': 'Borough_Boundaries-aplu6n',
            layout: {
              visibility: 'none',
            },
            paint: {
              'line-color': '#e4df5e',
              'line-width': ['interpolate', ['linear'], ['zoom'], 10, 2, 22, 8],
            },
          },
        },
        {
          html_name: 'Subway Lines',
          name: 'Subway_Lines-0ovpqb',
          type: 'vector',
          url: 'mapbox://zlvas.amp02gbp',
          layer: {
            id: 'Subway_Lines-0ovpqb',
            type: 'line',
            source: 'Subway_Lines-0ovpqb',
            'source-layer': 'Subway_Lines-0ovpqb',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'line-color': [
                'match',
                ['get', 'name'],
                [
                  'N-Q-R',
                  'N-Q-R-W',
                  'N-R-W',
                  'N',
                  'N-Q',
                  'N-R',
                  'N-W',
                  'R-W',
                  'R',
                  'Q',
                ],
                'hsl(48, 97%, 51%)',
                ['A', 'E', 'A-C-E', 'A-C'],
                'hsl(220, 100%, 32%)',
                ['B', 'D', 'F', 'F-M', 'M', 'B-D-F-M', 'B-D'],
                'hsl(19, 90%, 64%)',
                ['4-5-6', '4-5', '4', '5', '6'],
                'hsl(144, 100%, 29%)',
                ['G'],
                'hsl(98, 50%, 50%)',
                ['L'],
                'hsl(228, 3%, 67%)',
                ['J-Z'],
                'hsl(29, 50%, 40%)',
                ['2-3', '1-2-3', '1', '2', '3'],
                'hsl(3, 85%, 56%)',
                ['7'],
                'hsl(306, 53%, 57%)',
                ['S'],
                'hsl(220, 2%, 59%)',
                '#000000',
              ],
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10,
                2,
                17,
                4,
                22,
                5,
              ],
            },
          },
        },
        {
          html_name: 'Subway Lines(stations)',
          name: 'Subway_Stations-0g0jn1',
          type: 'vector',
          url: 'mapbox://zlvas.9jxrbyqo',
          layer: {
            id: 'Subway_Stations-0g0jn1',
            type: 'circle',
            source: 'Subway_Stations-0g0jn1',
            'source-layer': 'Subway_Stations-0g0jn1',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'circle-color': 'hsl(0, 0%, 100%)',
              'circle-stroke-color': 'hsl(0, 0%, 0%)',
              'circle-stroke-width': 1.5,
              'circle-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10,
                3,
                12,
                3,
                14,
                5,
              ],
              'circle-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                11,
                0,
                12.1,
                1,
              ],
              'circle-stroke-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                11,
                0,
                12.1,
                1,
              ],
            },
          },
        },
        {
          html_name: 'street text',
          name: 'street_text',
          type: 'vector',
          url: 'mapbox://zlvas.6niywhak',
          layer: {
            id: 'street_text',
            type: 'symbol',
            source: 'street_text',
            'source-layer': 'DCM_StreetCenterLine_filtrado-6a49b3',
            layout: {
              'text-field': [
                'concat',
                ['get', 'Street_NM'],
                ' (',
                ['get', 'Streetwidt'],
                ' ft)',
              ],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14,
                10,
                16,
                15,
                22,
                15,
              ],
              'symbol-placement': 'line',
              visibility: 'visible',
            },
            paint: {
              'text-color': '#ffffff',
              'text-halo-color': '#000000',
              'text-halo-width': 1,
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14,
                0,
                16,
                1,
                22,
                1,
              ],
            },
          },
        },
        {
          html_name: 'lots',
          name: 'lots',
          type: 'vector',
          url: 'mapbox://zlvas.09f2dj8k',
          layer: {
            id: 'lots',
            type: 'fill',
            source: 'lots',
            'source-layer': 'taxlot-2eq758',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'fill-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                '#0080ff',
                '#aaa',
              ],
              'fill-opacity': 0,
            },
          },
        },
        {
          html_name: 'Topography (Labels)',
          name: 'contour_4326_labels',
          type: 'vector',
          url: 'mapbox://zlvas.apa07f9n',
          layer: {
            id: 'contour_4326_labels',
            type: 'symbol',
            source: 'contour_4326_labels',
            'source-layer': 'contour_4326',
            layout: {
              'text-field': ['to-string', ['get', 'ELEVATION']],
              'symbol-placement': 'line-center',
              'text-size': 12,
              visibility: 'none',
            },
            paint: {
              'text-halo-width': 20,
              'text-halo-color': '#4d4d4d',
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                18.5,
                0,
                19,
                1,
              ],
            },
          },
        },
        {
          html_name: 'lots(Face Labels)',
          name: 'lot_face_labels',
          type: 'vector',
          url: 'mapbox://zlvas.77uje5wm',
          layer: {
            id: 'lot_face_labels',
            type: 'symbol',
            source: 'lot_face_labels',
            'source-layer': 'taxface_v2_4326',
            layout: {
              'text-field': [
                'case',
                ['==', ['get', 'LOT_FACE_L'], 0],
                'N/A',
                ['to-string', ['get', 'LOT_FACE_L']],
              ],
              'text-justify': 'right',
              'symbol-placement': 'line-center',
              'text-offset': [0, 1],
              'text-size': 12,
              visibility: 'none',
            },
            paint: {
              'text-color': '#a1a1a1',
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                18.5,
                0,
                19,
                1,
              ],
            },
          },
        },
        {
          html_name: 'lots(Labels)',
          name: 'lot_labels',
          type: 'vector',
          url: 'mapbox://zlvas.09f2dj8k',
          layer: {
            id: 'lot_labels',
            type: 'symbol',
            source: 'lot_labels',
            'source-layer': 'taxlot-2eq758',
            layout: {
              'text-field': ['to-string', ['get', 'LOT']],
              'text-size': 12,
              visibility: 'visible',
            },
            paint: {
              'text-color': 'hsl(0, 0%, 100%)',
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                17.5,
                0,
                18,
                1,
              ],
            },
          },
        },
        {
          html_name: 'Commercial Overlay (C1_2-C1_5-Labels)',
          name: 'Commercial_Overlay_c1_1_labels',
          type: 'vector',
          url: 'mapbox://zlvas.d66u3nph',
          layer: {
            id: 'Commercial_Overlay_c1_1_labels',
            type: 'symbol',
            source: 'Commercial_Overlay_c1_1_labels',
            'source-layer': 'Commercial_Overlay-c2emjy',
            layout: {
              'text-field': ['to-string', ['get', 'OVERLAY']],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14,
                10,
                17,
                20,
              ],
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
              visibility: 'visible',
            },
            paint: {
              'text-color': 'hsl(0, 82%, 63%)',
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0,
                14,
                1,
              ],
              'text-halo-color': 'hsla(0, 0%, 0%, 0.61)',
              'text-halo-width': 1,
              'text-halo-blur': 0.5,
            },
            filter: [
              'in',
              ['get', 'OVERLAY'],
              ['literal', ['C1-1', 'C1-2', 'C1-3', 'C1-4', 'C1-5']],
            ],
          },
        },
        {
          html_name: 'Commercial Overlay (C2_2-C2_5-Labels)',
          name: 'Commercial_Overlay_c2_1_labels',
          type: 'vector',
          url: 'mapbox://zlvas.d66u3nph',
          layer: {
            id: 'Commercial_Overlay_c2_1_labels',
            type: 'symbol',
            source: 'Commercial_Overlay_c2_1_labels',
            'source-layer': 'Commercial_Overlay-c2emjy',
            layout: {
              'text-field': ['to-string', ['get', 'OVERLAY']],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14,
                10,
                17,
                20,
              ],
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
              visibility: 'visible',
            },
            paint: {
              'text-color': 'hsl(0, 82%, 63%)',
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0,
                14,
                1,
              ],
              'text-halo-color': 'hsla(0, 0%, 0%, 0.61)',
              'text-halo-width': 1,
              'text-halo-blur': 0.5,
            },
            filter: [
              'in',
              ['get', 'OVERLAY'],
              ['literal', ['C2-1', 'C2-2', 'C2-3', 'C2-4', 'C2-5']],
            ],
          },
        },
        {
          html_name: 'Zoning Districts(Parks-Labels)',
          name: 'zoning_districts_p_labels',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_p_labels',
            type: 'symbol',
            source: 'zoning_districts_p_labels',
            'source-layer': 'Zoning_Districts-bt7q50',
            layout: {
              'text-field': ['to-string', ['get', 'ZONEDIST']],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14,
                10,
                17,
                20,
              ],
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
              visibility: 'visible',
            },
            paint: {
              'text-color': 'hsl(0, 0%, 100%)',
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0,
                14,
                1,
              ],
              'text-halo-color': 'hsl(0, 0%, 0%)',
              'text-halo-width': 1,
              'text-halo-blur': 0.5,
            },
            filter: [
              'in',
              ['get', 'ZONEDIST'],
              ['literal', ['PARK', 'BALL FIELD', 'PUBLIC PLACE', 'PLAYGROUND']],
            ],
          },
        },
        {
          html_name: 'Zoning Districts(Battery-Labels)',
          name: 'zoning_districts_b_labels',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_b_labels',
            type: 'symbol',
            source: 'zoning_districts_b_labels',
            'source-layer': 'Zoning_Districts-bt7q50',
            layout: {
              'text-field': ['to-string', ['get', 'ZONEDIST']],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14,
                10,
                17,
                20,
              ],
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
              visibility: 'visible',
            },
            paint: {
              'text-color': 'hsl(0, 0%, 100%)',
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0,
                14,
                1,
              ],
              'text-halo-color': 'hsl(0, 0%, 0%)',
              'text-halo-width': 1,
              'text-halo-blur': 0.5,
            },
            filter: ['==', ['get', 'ZONEDIST'], 'BPC'],
          },
        },
        {
          html_name: 'Zoning Districts(Residence-Labels)',
          name: 'zoning_districts_r_labels',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_r_labels',
            type: 'symbol',
            source: 'zoning_districts_r_labels',
            'source-layer': 'Zoning_Districts-bt7q50',
            layout: {
              'text-field': ['to-string', ['get', 'ZONEDIST']],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14,
                10,
                17,
                20,
              ],
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
              visibility: 'visible',
            },
            paint: {
              'text-color': 'hsl(0, 0%, 100%)',
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0,
                14,
                1,
              ],
              'text-halo-color': 'hsl(0, 0%, 0%)',
              'text-halo-width': 1,
              'text-halo-blur': 0.5,
            },
            filter: ['==', ['index-of', 'R', ['get', 'ZONEDIST']], 0],
          },
        },
        {
          html_name: 'Zoning Districts(Commercial-Labels)',
          name: 'zoning_districts_c_labels',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_c_labels',
            type: 'symbol',
            source: 'zoning_districts_c_labels',
            'source-layer': 'Zoning_Districts-bt7q50',
            layout: {
              'text-field': ['to-string', ['get', 'ZONEDIST']],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14,
                10,
                17,
                20,
              ],
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
              visibility: 'visible',
            },
            paint: {
              'text-color': 'hsl(0, 0%, 100%)',
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0,
                14,
                1,
              ],
              'text-halo-color': 'hsl(0, 0%, 0%)',
              'text-halo-width': 1,
              'text-halo-blur': 0.5,
            },
            filter: ['==', ['index-of', 'C', ['get', 'ZONEDIST']], 0],
          },
        },
        {
          html_name: 'Zoning Districts(Manufacturing-Labels)',
          name: 'zoning_districts_m_labels',
          type: 'vector',
          url: 'mapbox://zlvas.dj2o4z0g',
          layer: {
            id: 'zoning_districts_m_labels',
            type: 'symbol',
            source: 'zoning_districts_m_labels',
            'source-layer': 'Zoning_Districts-bt7q50',
            layout: {
              'text-field': ['to-string', ['get', 'ZONEDIST']],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14,
                10,
                17,
                20,
              ],
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
              visibility: 'visible',
            },
            paint: {
              'text-color': 'hsl(0, 0%, 100%)',
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0,
                14,
                1,
              ],
              'text-halo-color': 'hsl(0, 0%, 0%)',
              'text-halo-width': 1,
              'text-halo-blur': 0.5,
            },
            filter: ['==', ['index-of', 'M', ['get', 'ZONEDIST']], 0],
          },
        },
        {
          html_name: 'Community District(Labels)',
          name: 'community_districts_l',
          type: 'vector',
          url: 'mapbox://zlvas.4gxhdsd5',
          layer: {
            id: 'community_districts_l',
            type: 'symbol',
            source: 'community_districts_l',
            'source-layer': 'Community_Districts-11qfco',
            layout: {
              'text-field': ['to-string', ['get', 'BOROUGH']],
              'text-size': ['interpolate', ['linear'], ['zoom'], 0, 5, 22, 20],
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
              visibility: 'none',
            },
            paint: {
              'text-color': '#ffffff',
              'text-halo-color': 'hsl(0, 0%, 0%)',
              'text-halo-width': 1,
              'text-halo-blur': 0.5,
            },
          },
        },
        {
          html_name: 'Boroughs (Labels)',
          name: 'borough_boundaries_l',
          type: 'vector',
          url: 'mapbox://zlvas.0zhzqqj4',
          layer: {
            id: 'borough_boundaries_l',
            type: 'symbol',
            source: 'borough_boundaries_l',
            'source-layer': 'Borough_Boundaries-aplu6n',
            layout: {
              'text-field': [
                'step',
                ['zoom'],
                ['to-string', ['get', 'boro_name']],
                22,
                ['to-string', ['get', 'boro_name']],
              ],
              'text-size': ['interpolate', ['linear'], ['zoom'], 0, 5, 22, 20],
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
              visibility: 'none',
            },
            paint: {
              'text-color': '#ffffff',
              'text-halo-color': 'hsl(0, 0%, 0%)',
              'text-halo-blur': 0.5,
              'text-halo-width': 1,
            },
          },
        },
        {
          html_name: 'Historict District(Labels)',
          name: 'Historict_District_l',
          type: 'vector',
          url: 'mapbox://zlvas.7icesbej',
          layer: {
            id: 'Historict_District_l',
            type: 'symbol',
            source: 'Historict_District_l',
            'source-layer': 'Historic_Districts-c5us3r',
            layout: {
              'text-field': ['to-string', ['get', 'area_name']],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14,
                10,
                17,
                20,
              ],
              visibility: 'none',
            },
            paint: {
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0,
                14,
                1,
              ],
              'text-color': 'hsl(233, 83%, 87%)',
              'text-halo-color': 'hsl(0, 0%, 0%)',
              'text-halo-width': 1,
              'text-halo-blur': 0.5,
            },
          },
        },
        {
          html_name: 'Special Purpose District(Labels)',
          name: 'Special_Purpose_District_l',
          type: 'vector',
          url: 'mapbox://zlvas.ay3m33tv',
          layer: {
            id: 'Special_Purpose_District_l',
            type: 'symbol',
            source: 'Special_Purpose_District_l',
            'source-layer': 'Special_Purpose_District-5s1rri',
            layout: {
              'text-field': ['to-string', ['get', 'SDNAME']],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14,
                10,
                17,
                20,
              ],
              visibility: 'none',
            },
            paint: {
              'text-color': 'hsl(102, 29%, 75%)',
              'text-halo-width': 1,
              'text-halo-blur': 0.5,
              'text-halo-color': 'hsl(312, 0%, 0%)',
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0,
                14,
                1,
              ],
            },
          },
        },
        {
          html_name: 'Special Purpose Subdistrict(Labels)',
          name: 'Special_Purpose_Subdistrict_l',
          type: 'vector',
          url: 'mapbox://zlvas.c4xqa2rb',
          layer: {
            id: 'Special_Purpose_Subdistrict_l',
            type: 'symbol',
            source: 'Special_Purpose_Subdistrict_l',
            'source-layer': 'Special_Purpose_Subdistrict-dit7bw',
            layout: {
              'text-field': [
                'case',
                ['==', ['get', 'SUBDIST'], 'None'],
                ['concat', ['get', 'SPLBL'], ' - ', ['get', 'SUBAREA_LB']],
                [
                  'concat',
                  ['get', 'SPLBL'],
                  ' - ',
                  ['get', 'SUBDIST'],
                  ' - ',
                  ['get', 'SUBAREA_LB'],
                ],
              ],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14,
                10,
                17,
                20,
              ],
              visibility: 'none',
            },
            paint: {
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0,
                14,
                1,
              ],
              'text-halo-color': 'hsl(0, 0%, 0%)',
              'text-halo-width': 1,
              'text-halo-blur': 0.5,
              'text-color': 'hsl(102, 66%, 77%)',
            },
          },
        },
        {
          html_name: 'Limited Height Disctricts(Labels)',
          name: 'Limited_Height_Districts_l',
          type: 'vector',
          url: 'mapbox://zlvas.6dudr1uu',
          layer: {
            id: 'Limited_Height_Districts_l',
            type: 'symbol',
            source: 'Limited_Height_Districts_l',
            'source-layer': 'Limited_Height_Districts-3i64wv',
            layout: {
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                14,
                10,
                17,
                20,
              ],
              'text-field': ['get', 'LHLBL'],
              visibility: 'none',
            },
            paint: {
              'text-color': 'hsl(42, 58%, 47%)',
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                13,
                0,
                14,
                1,
              ],
              'text-halo-color': 'hsl(0, 0%, 0%)',
              'text-halo-width': 1,
              'text-halo-blur': 0.5,
            },
          },
        },
        {
          html_name: 'Subway Lines(Labels)',
          name: 'subway_stations_l',
          type: 'vector',
          url: 'mapbox://zlvas.9jxrbyqo',
          layer: {
            id: 'subway_stations_l',
            type: 'symbol',
            source: 'subway_stations_l',
            'source-layer': 'Subway_Stations-0g0jn1',
            layout: {
              'text-field': ['to-string', ['get', 'name']],
              'text-size': 13,
              'text-anchor': 'top',
              'text-offset': [0, 0.5],
              visibility: 'visible',
            },
            paint: {
              'text-color': 'hsl(0, 0%, 100%)',
              'text-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                17,
                0,
                17.5,
                1,
              ],
            },
          },
        },
      ],
    },
  };

  /*
  Tax Lot * Lots,*lots(Outline)*lots(labels),*lots(Face Labels)
    *Tax Lot (Landuse)
    *Tax Lot(Outline Landuse)
  Commercial Districts
    *Zoning Districts(Commercial)
    *Zoning Districts(Commercial Outline)
    *Zoning Districts(Commercial-Labels)
  Manufacturing
    *Zoning Districts(Manufacturing)
    *Zoning Districts(Manufacturing Outline)
    *Zoning Districts(Manufacturing-Labels)
  Residence Districts
    *Zoning Districts(Residence)
    *Zoning Districts(Residence Outline)
    *Zoning Districts(Residence-Labels)
  Park
    *Zoning Districts(Parks)
    *Zoning Districts(Parks Outline)
    *Zoning Districts(Parks-Labels)
  Battery
    *Zoning Districts(Battery)
    *Zoning Districts(Battery Outline)
    *Zoning Districts(Battery-Labels)
  C1-1
    *Commercial Overlay(C1_2-C1_5)
    *Commercial Overlay (C1_2-C1_5-Labels)
  C2-1
    *Commercial Overlay(C2_2-C2_5)
    *Commercial Overlay (C2_2-C2_5-Labels)
  Special Purpose Districs
    *Special Purpose District
    *Special Purpose District(Outline)
    *Special Purpose District(Labels)
  Special Purpose subDistrics
    *Special Purpose Subdistrict
    *Special Purpose Subdistrict(Outline)
    *Special Purpose Subdistrict(Labels)
  Community Districs
    *Community District
    *Community District(Labels)
  Effective Flood V
    *Effective Flood Maps 2007(V)
  Effective Flood A
    *Effective Flood Maps 2007(A)
  Effective Flood X
    *Effective Flood Maps 2007(X)
  Preliminary Flood V
    *Preliminary Flood Maps 2015(V)
  Preliminary Flood A
    *Preliminary Flood Maps 2015(A)
  Preliminary Flood X
    *Preliminary Flood Maps 2015(X)
  Historical
    *Historict District
    *Historict District(Outline)
  Landmark
    *Landmark Sites
  E Designation
    *E Designation
  Subway
    *Subway Lines
    *Subway Lines(stations)
    *Subway Lines(Labels)
  Topography
    *Topography
    *Topography (Labels)
  Limited Height
    *Limited Height Disctricts
    *Limited Height Disctricts(Outline)
    *Limited Height Disctricts(Labels)
  IHDA
    *IHDA
    *IHDA(Outline)
  MIH
    *MIH
    *MIH(Outline)
  */

  mapOptionList = [
    {
      id: 0,
      option: 'map up view',
      enabled: false,
      image: '../../../assets/images/up-map.svg',
      hasSliderOpacity: false,
    },
    {
      id: 1,
      option: 'map 2d',
      enabled: false,
      image: '../../../assets/images/2d-map.svg',
      hasSliderOpacity: true,
    },
    {
      id: 2,
      option: 'map 3d',
      enabled: false,
      image: '../../../assets/images/3d-map.svg',
      hasSliderOpacity: false,
    },
  ];

  /**
   * Assested total value min value
   */
  opacityMinValue: number = 50;

  /**
   * Assested total value min value
   */
  opacityMaxValue: number = 100;
  padding: number = 0;
  loading: boolean = false;
  opacity: number = 50; //0 a 100
  bbl: number = -1;
  typeAreaExport: number = -1;
  selectedOptionOn: string = '';
  selectedOptionOff: string = '';
  selectedOptionToogle: string = '';

  /**
   * Assested total value input range options
   */
  opacitySliderOptions: Options = {
    disabled: true,
    floor: 0,
    ceil: 100,
    step: 1,
    showTicks: false,

    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + '%';
        default:
          return '';
      }
    },
  };

  /**
   * Subscription
   */
  subscription!: Subscription;
  enableExtrudeBtn: boolean = false;

  monkey: any;
  useBulk: any;
  memory: any;

  opacitySliderController!: boolean;
  sateliteMode!: boolean;
  showBuilding!: boolean;
  disabledOptionMap: boolean = false;
  show2d!: boolean;
  streetview: any = null;
  showStreetview: boolean = false;

  selectBBL2_0: string = '';
  BBL2_0: any = [];
  useBulkAvailable: any = [];
  selectedOptions: { [key: string]: boolean } = {};

  /**
   * params of snackbar
   */
  paramsSnack: SnackbarTO = {
    message: '',
    type: '',
    visible: false,
    position: [],
  } as SnackbarTO;

  /**
   * constructor
   * @param darkmodeService servicio de dark mode configuration
   */
  constructor(
    private darkmodeService: DarkModeService,
    private printService: PrintService,
    private manageLayersService: ManageLayerService,
    private zoningDataService: ZoningDataService,
    private zoningAnalysisService: ZoningAnalysisService,
    private setPaddingService: SetPaddingService,
    private getLotService: GetLotService,
    private setTagEnvelopeService: SetTagEnvelopeService,
    private router: Router,
    private loadingService: LoadingService,
    private selectUseService: SelectUseService,
    private backendService: BackendService,
    private disabledElementsExtrude: DisabledElementsExtrude,
    private addressSearchService: AddressSearchService,
    private navOptionExtent: NavOptionExtent,
    private modalService: NgbModal,
    private setAreaService: SetAreaService,
    private modalView: ModalViewService,
    private visualizePdfFeature: VisualizePdfFeatureService
  ) {
    this.subscription = EMPTY.pipe(tap(() => null)).subscribe();
  }

  ngOnInit(): void {
    this.monkey = new zlvas(this.config, window, Threebox);
    this.monkey?.getLotBBL?.subscribe((data:any) => {
      const  bbl = data.bbl
      const  tag = data.tag
      if (this.bbl !== bbl) {
        this.bbl = bbl;
        console.log('Click lot: ', bbl);
        this.useBulk = null;
        sessionStorage.setItem('bbl', bbl);
        this.zoningDataService.setZoningInfo();
        this.navOptionExtent.activateExtent(true);
        this.addressSearchService.setBBL(bbl);
        //Observador retorna toda la información de un BBL
        this.backendService.getDataFromBBL(bbl,tag).subscribe(
          (res: any) => {
            this.zoningDataService.setZoningInfo(res);
          },
          (err) => {
            if (err.status === 404) {
              const position = [50, 34];
              let params: SnackbarTO = {
                message: 'No results found',
                position: position,
                visible: true,
                type: 'alert',
                center: true,
              } as SnackbarTO;
              this.paramsSnack = params;
              setTimeout(() => {
                let params: SnackbarTO = {
                  message: 'No results found',
                  position: position,
                  visible: false,
                  type: 'alert',
                  center: true,
                } as SnackbarTO;
                this.paramsSnack = params;
              }, 3000);
            } else {
              this.navigateToLogin();
            }
          }
        );

        /**
         * Method to get uses from BBL
         */
        this.backendService.getSelectUseAvailable(bbl).subscribe((res: any) => {
          //console.log(res)
          this.BBL2_0 = res;
          if (res && res.BBL_2 && res.BBL_2.length > 0) {
            // La propiedad BBL_2 existe en res y tiene al menos un elemento
            this.useBulk = new useBulk(res.BBL_2);
          } else {
            // La propiedad BBL_2 no está presente o está vacía
            console.warn('error BBL_2 not found ');
          }

          this.useBulkAvailable = this.useBulk.getUse();

          // para el bbl 1010290027
          console.log('this.useBulk.getUse()', this.useBulk.getUse());

          /*
para el bbl 1010290027 el metodo this.useBulk.getUse(), retorna lo siguiente

son dos elementos: (existe un error en la libreria ya que no esta devolviendo el campo OVERLAY)  

          [
            {
                "INDEX": 17970,
                "BBL": 1010290027,
                "ZONEDIST": "C5-3",
                "R25": false,
                "R65": false,
                "USES": {
                    "R": [
                        "UG1",
                        "UG2"
                    ],
                    "CF": [
                        "UG3",
                        "UG4"
                    ],
                    "C": [
                        "UG5",
                        "UG6",
                        "UG9",
                        "UG10",
                        "UG11"
                    ],
                    "M": []
                },
                "BULKS": {
                    "QH": true,
                    "QGF": true,
                    "BH": true,
                    "AH": true,
                    "TB": true,
                    "ST_BH": true,
                    "ST_AH": true,
                    "SD": false
                },
                "INPUT": {
                    "INDEX": 17970,
                    "R": false,
                    "CF": false,
                    "C": false,
                    "M": false,
                    "R_QUANTITY": 50
                }
            },
            {
                "INDEX": 17971,
                "BBL": 1010290027,
                "ZONEDIST": "C6-6",
                "R25": false,
                "R65": false,
                "USES": {
                    "R": [
                        "UG1",
                        "UG2"
                    ],
                    "CF": [
                        "UG3",
                        "UG4"
                    ],
                    "C": [
                        "UG5",
                        "UG6",
                        "UG7",
                        "UG8",
                        "UG9",
                        "UG10",
                        "UG11",
                        "UG12"
                    ],
                    "M": []
                },
                "BULKS": {
                    "QH": true,
                    "QGF": true,
                    "BH": true,
                    "AH": true,
                    "TB": true,
                    "ST_BH": true,
                    "ST_AH": true,
                    "SD": false
                },
                "INPUT": {
                    "INDEX": 17971,
                    "R": false,
                    "CF": false,
                    "C": false,
                    "M": false,
                    "R_QUANTITY": 50
                }
            }
        ]

****Barras****
"R25": false,"R65": false -> no tiene barra
"R25": true,"R65": true -> barra de 3 opciones
"R25": true,"R65": false -> barra de 2 opciones

********************************seccion de usos*******************

ZONEDIST / OVERLAY (este es el formato cuando existe OVERLAY, si no tenemos overlay, se muetra solo ZONEDIST )

C5-3
          segun "USES", usos activables -> R-CF-C
          segun "USES", usos desactivados -> M
          "R25": false,"R65": false -> no tiene barra
C6-6
          segun "USES", usos activables -> R-CF-C
          segun "USES", usos desactivados -> M
          "R25": false,"R65": false -> no tiene barra

****************************************************

*************seccion de bulks******************
C5-3, con "BULKS"
  "QH": true -> en boton QH se muestra C5-3 en estado desabilitado
  "QGF": true -> en boton QGF se muestra C5-3 en estado desabilitado
  "BH": true -> en boton BH se muestra C5-3 en estado desabilitado
  "AH": true -> en boton AH se muestra C5-3 en estado desabilitado
  "TB": true -> en boton TB se muestra C5-3 en estado desabilitado
  "ST_BH": true -> en boton ST_BH se muestra C5-3 en estado desabilitado
  "ST_AH": true -> en boton ST_AH se muestra C5-3 en estado desabilitado
  "SD": false -> en boton SD no se muestra C5-3 

C6-6, con "BULKS"
  "QH": true -> en boton QH se muestra C6-6 en estado desabilitado
  "QGF": true -> en boton QGF se muestra C6-6 en estado desabilitado
  "BH": true -> en boton BH se muestra C6-6 en estado desabilitado
  "AH": true -> en boton AH se muestra C6-6 en estado desabilitado
  "TB": true -> en boton TB se muestra C6-6 en estado desabilitado
  "ST_BH": true -> en boton ST_BH se muestra C6-6 en estado desabilitado
  "ST_AH": true -> en boton ST_AH se muestra C6-6 en estado desabilitado
  "SD": false -> en boton SD no se muestra C6-6 

*/

          /*Al json que queda en this.useBulkAvailable 

  Utilizo el campo que ya viene "BULKS" para identificar si se muestra o no el elemento sobre los botones de bulk.Lo que se muestran estan en estado disabled
  Creo otro "BULKS_ACTIVE", donde el estado inicial es igual a "BULKS", "BULKS_ACTIVE" lo utilizo para cambiar el estado segun la  funcion getBulk()
  ,enabled o disabled
  
  */

          this.useBulkAvailable.forEach((e: any) => {
            e.BULKS_ACTIVE = e.BULKS;
            e.R_BAR = false;
            e.YARDS_ACTIVE = e.YARDS;
          });
          sessionStorage.setItem(
            'useBulkAvailableInfo',
            JSON.stringify(this.useBulkAvailable)
          );
          sessionStorage.setItem(
            'getSelectUseAvailableRes',
            JSON.stringify(res)
          );
          this.selectUseService.setSelectUseInfo(true);
          console.log('uses', this.useBulkAvailable);
        });
      }
      if (this.showStreetview) this.openStreetView();
    });

    this.monkey.getEnvelopeState.subscribe((state: boolean) => {
      console.log('Map Envelope ready', state);
      this.disabledOptionMap = !state;
    });

    this.monkey.getMapState.subscribe((state: boolean) => {
      console.log('Map Ready', state);
      this.loading = !state;
    });

    this.subscription = this.darkmodeService.darkModeData.subscribe(
      (data: any) => {
        if (data?.activeTheme === Constants.LIGHT) {
          this.setLightmode();
        } else {
          this.setDarkmode();
        }
      }
    );

    this.subscription =
      this.visualizePdfFeature.visualizePdfFeatureData.subscribe(
        (data: any) => {
          this.loading = true;
          this.getPdfFeature();
        }
      );

    this.subscription = this.printService.printData.subscribe((data: any) => {
      this.loading = true;
      if (data.bbl) {
        this.exportPDF(data.bbl);
      }
    });

    this.subscription = this.zoningAnalysisService.zoningAnalysisData.subscribe(
      (data?: any) => {
        if (data?.dataConfig) {
          this.backendService
          .getAssemble(data.dataConfig)
          .subscribe((res: any) => {
            console.log(res);
            let loadingvalue: any = null;
            if (!!data?.dataConfig) {
              this.loading = true;
              this.monkey.getEnvelopeState
                .pipe(take(2))
                .subscribe((state: boolean) => {
                  loadingvalue = !state;
                  this.loading = !state;
                  this.disabledElementsExtrude.disabledElement(loadingvalue);
                });
              this.enableExtrudeBtn = true;
            }
            this.monkey.getAssembler(res.message, window);

            //this.monkey.getAssembler(res.message,window,Threebox,true)
          });
        }
      },
      (err) => {
        if (err) {
          this.navigateToLogin();
        }
      }
    );

    this.subscription = this.manageLayersService.layerData.subscribe(
      (data: any) => {
        if (!!data?.activate) {
          this.setLayerOn(data.layer);
        } else {
          if (data.layersList.length === 0) {
            this.setLayerOff(data.layer);
          }
        }
      },
      (err) => {
        if (err) {
          this.navigateToLogin();
        }
      }
    );

    this.subscription = this.getLotService.getLotData.subscribe(
      (data: any) => {
        if (!!data) {
          this.searchToBBL(data);
        }
      },
      (err) => {
        if (err) {
          this.navigateToLogin();
        }
      }
    );

    this.subscription = this.manageLayersService.layerData.subscribe(
      (data: any) => {
        if (!!data?.activate) {
          this.setLayerOn(data.layer);
        } else {
          if (data.layersList.length === 0) {
            this.setLayerOff(data.layer);
          }
        }
      },
      (err) => {
        if (err) {
          this.navigateToLogin();
        }
      }
    );

    this.subscription = this.setPaddingService.setPaddingData.subscribe(
      (data: any) => {
        if (!!data?.actualPadding) {
          this.setPadding(data.actualPadding);
        } else {
          if (data?.actualPadding === 0) {
            this.setPadding(0);
          }
        }
      }
    );

    this.subscription = this.setTagEnvelopeService.setTagEnvelopeData.subscribe(
      (data: any) => {
        if (!data?.tagValue) {
          this.setLabelHeightOn();
        } else {
          this.setLabelHeightOff();
        }
      },
      (err) => {
        if (err) {
          this.navigateToLogin();
        }
      }
    );

    this.subscription = this.zoningAnalysisService.zoningAnalysisData.subscribe(
      (data?: any) => {
        if (data?.removeAction) {
          this.removeEnvelope();
          this.enableExtrudeBtn = false;
        }
      },
      (err) => {
        if (err) {
          this.navigateToLogin();
        }
      }
    );

    this.subscription = this.setAreaService.setAreaData.subscribe(
      (data?: any) => {
        this.setAreaExport(data?.option);
      }
    );

    this.subscription = this.modalView.modalViewData.subscribe((data?: any) => {
      this.openModal(data);
    });

    this.subscription = this.zoningAnalysisService.zoningAnalysisData.subscribe(
      (data?: any) => {
        if (data?.useTable) {
          const zonedist = data.useTable[0].type
          const overlay = data.useTable[1].type
          this.openUseTable(overlay != '' ? zonedist + '/' + overlay : zonedist)
        }
      }
    );

    setTimeout(() => {
      this.monkey?.setDarkMode();
    }, 1000);
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Method that navigate to login
   */
  navigateToLogin() {
    this.loadingService.show();
    this.router.navigate(['/login/']);
    this.loadingService.hide();
    window.location.reload();
  }

  /**
   * Method that set darkmode
   */
  setDarkmode() {
    this.monkey?.setDarkMode();
  }

  /**
   * Method that sets light mode
   */
  setLightmode() {
    this.monkey?.setLightMode();
  }

  /**
   * Method that sets the satellite mode
   */
  setSatellitemode() {
    this.monkey?.setSatelliteMode();
  }

  /**
   * Method that set padding in the map
   * @param padding padding value
   */
  setPadding(padding: number) {
    this.padding = padding;
    this.monkey?.setPadding(padding);
  }

  /**
   * Method that remove envelopes in the map
   */
  removeEnvelope() {
    this.monkey?.removeEnvelope(window, Threebox);
  }

  /**
   * Method that change the state between visible and not visible for the layer 3d
   */
  toggle3d() {
    this.monkey?.toggle3dLayer();
  }

  /**
   * Method to get bukls from BBL
   */

  getBulks() {
    const request: any = [];
    const assembleList = document.getElementById('assemble');

    console.log('assembleList', assembleList);

    if (assembleList) {
      const subgroupElements = assembleList.querySelectorAll('.subgroup');

      console.log('subgroupElements', subgroupElements);

      subgroupElements.forEach((subgroup: any) => {
        const inputs = subgroup.querySelectorAll('input');

        let bbl: {
          INDEX: number;
          R: boolean;
          CF: boolean;
          C: boolean;
          M: boolean;
          R_QUANTITY: number;
        } = {
          INDEX: parseInt(subgroup.id),
          R: false,
          CF: false,
          C: false,
          M: false,
          R_QUANTITY: 0,
        };

        inputs.forEach((input: HTMLInputElement) => {
          if (input.type === 'checkbox' && input.name == 'use') {
            if (input.checked) {
              if (input.value == 'R') bbl.R = true;
              if (input.value == 'CF') bbl.CF = true;
              if (input.value == 'C') bbl.C = true;
              if (input.value == 'M') bbl.M = true;
            }
          } else if (
            input.type === 'radio' &&
            input.name == `rMinus25_${subgroup.id}` &&
            input.checked
          ) {
            bbl.R_QUANTITY = parseInt(input.value);
          }
        });

        if (bbl.R && !bbl.CF && !bbl.C && !bbl.M) {
          bbl.R_QUANTITY = 100;
        }
        if (!bbl.R && !bbl.CF && !bbl.C && !bbl.M) {
          bbl.R_QUANTITY = 0;
        }
        request.push(bbl);
      });
    }

    console.log('request', request);
    console.log('this.useBulk', this.useBulk.getBulk(request));

    const bulks = this.useBulk.getBulk(request).map((e: any) => {
      e.BULKS.QH = !e.BULKS.QH;
      e.BULKS.QGF = !e.BULKS.QGF;
      e.BULKS.BH = !e.BULKS.BH;
      e.BULKS.AH = !e.BULKS.AH;
      e.BULKS.TB = !e.BULKS.TB;
      e.BULKS.ST_BH = !e.BULKS.ST_BH;
      e.BULKS.ST_AH = !e.BULKS.ST_AH;
      e.BULKS.SD = !e.BULKS.SD;
      e.INDEX = parseInt(e.INDEX);

      return e;
    });

    for (let i = 0; i < this.useBulkAvailable.length; i++) {
      const bbl = this.useBulkAvailable[i];
      for (let x = 0; x < bulks.length; x++) {
        if (bbl.INDEX == bulks[x].INDEX) {
          for (let z = 0; z < request.length; z++) {
            if (bbl.INDEX == request[z].INDEX) {
              if (
                !request[z].R &&
                !request[z].C &&
                !request[z].CF &&
                !request[z].M
              ) {
                bbl.BULKS_ACTIVE = bbl.BULKS;
              } else {
                bbl.BULKS_ACTIVE = bulks[x].BULKS;
              }

              if (
                request[z].R &&
                (request[z].C || request[z].CF || request[z].M)
              ) {
                bbl.R_BAR = true;
              } else {
                bbl.R_BAR = false;
              }
            }
          }
        }
      }
    }
  }
  /**
   * Method for buttons Assembler
   */
  getAssembler() {
    const template = [
      {
        INDEX: 1,
        BBL: 1000,
        USES: ['R', 'C'],
        BULK: 'QH',
        ZONEDIST: 'ZONEDIST',
        OVERLAY: 'OVERLAY',
      },
      {},
    ];

    const request: any = [];
    const assembleList = document.getElementById('assemble');

    console.log('assembleList', assembleList);

    if (assembleList) {
      const subgroupElements = assembleList.querySelectorAll('.subgroup');
      const subgroupElements2 = assembleList.querySelectorAll('.subgroup2');

      console.log('subgroupElements', subgroupElements);

      subgroupElements.forEach((subgroup: any) => {
        const inputs = subgroup.querySelectorAll('input');

        const bbl: {
          INDEX: number;
          BBL: number;
          USES: string[];
          BULK: string;
          ZONEDIST: string;
          OVERLAY: string;
          R25: boolean;
          YARD_TYPE: string;
        } = {
          INDEX: subgroup.id,
          BBL: 0,
          USES: [],
          BULK: '',
          ZONEDIST: '',
          OVERLAY: '',
          R25: false,
          YARD_TYPE: '',
        };

        subgroupElements2.forEach((subgroup2: any) => {
          const inputs2 = subgroup2.querySelectorAll('input');

          inputs2.forEach((input: HTMLInputElement) => {
            console.log(
              'input.name',
              input.name,
              input.value,
              input.checked,
              input.type
            );
            if (input.type === 'radio' && input.name == 'yard') {
              if (input.checked) {
                console.log('input.name', input.name, input.value);
                bbl.YARD_TYPE = input.value;
              }
            }
          });
        });

        inputs.forEach((input: HTMLInputElement) => {
          if (input.name == 'bbl') bbl.BBL = parseInt(input.value);
          if (input.name == 'zonedist') bbl.ZONEDIST = input.value;
          if (input.name == 'overlay') bbl.OVERLAY = input.value;
          // if(this.selectedRYE_HF != ''){
          //   bbl.YARD_TYPE = this.selectedRYE_HF
          //   bbl.YARD_USE = 'RYE_HF'
          // }

          // if(this.selectedRYE_QH != ''){
          //   bbl.YARD_TYPE = this.selectedRYE_QH
          //   bbl.YARD_USE = 'RYE_QH'
          // }

          // if (input.type === 'checkbox' && input.name == 'yard') {
          //   if (input.checked) {
          //     console.log('input.name',input.name,input.value)
          //     bbl.YARD_TYPE = input.value
          //   }
          // }

          if (input.type === 'checkbox' && input.name == 'use') {
            if (input.checked) {
              bbl.USES.push(input.value);
            }
          } else if (
            input.type === 'radio' &&
            input.name == `rMinus25_${subgroup.id}` &&
            input.checked
          ) {
            if (parseInt(input.value) <= 25) {
              bbl.R25 = true;
            } else {
              bbl.R25 = false;
            }
          } else if (
            input.type === 'radio' &&
            input.name == `bulk_${subgroup.id}`
          ) {
            if (input.checked) {
              bbl.BULK = input.value;
            }
          }
        });

        request.push(bbl);
      });
    }

    this.backendService.getAssemble(request).subscribe((res: any) => {
      console.log(res);
      this.monkey.getAssembler(res.message, window);

      //this.monkey.getAssembler(res.message,window,Threebox,true)
    });
  }
  /**
   * Method to get yards from use - bulk
   */
  getYards() {
    const request: any = [];
    const assembleList = document.getElementById('assemble');

    console.log('assembleList', assembleList);

    if (assembleList) {
      const subgroupElements = assembleList.querySelectorAll('.subgroup');

      console.log('subgroupElements', subgroupElements);

      subgroupElements.forEach((subgroup: any) => {
        const inputs = subgroup.querySelectorAll('input');

        const bbl: {
          INDEX: number;
          R: boolean;
          CF: boolean;
          C: boolean;
          M: boolean;
          BULK: string;
        } = {
          INDEX: parseInt(subgroup.id),
          R: false,
          CF: false,
          C: false,
          M: false,
          BULK: '',
        };

        inputs.forEach((input: HTMLInputElement) => {
          if (input.type === 'checkbox' && input.name == 'use') {
            if (input.checked) {
              if (input.value == 'R') bbl.R = true;
              if (input.value == 'CF') bbl.CF = true;
              if (input.value == 'C') bbl.C = true;
              if (input.value == 'M') bbl.M = true;
            }
          } else if (
            input.type === 'radio' &&
            input.name == `bulk_${subgroup.id}`
          ) {
            if (input.checked) {
              bbl.BULK = input.value;
            }
          }
        });

        request.push(bbl);
      });
    }

    const yardAvailable = this.useBulk.getYard(request).map((e: any) => {
      e.A = !e.A;
      e.B_1 = !e.B_1;
      e.B_2 = !e.B_2;
      e.B_3 = !e.B_3;
      e.C = !e.C;
      return e;
    });
    console.log('request', request);
    console.log('yardAvailable', yardAvailable);

    this.useBulkAvailable.forEach((e: any) => {
      e.YARDS_ACTIVE = {
        A: yardAvailable[0].A,
        B_1: yardAvailable[0].B_1,
        B_2: yardAvailable[0].B_1,
        B_3: yardAvailable[0].B_1,
        C: yardAvailable[0].C,
      };
    });
  }

  /**
   * Method that change the states to visible for layer 3d
   */
  set3dOn() {
    this.monkey?.set3dLayer(true);
  }

  /**
   * Method that change the state to not visible for layer 3d
   */
  set3dOff() {
    this.monkey?.set3dLayer(false);
  }

  /**
   * Method that only shows buildings for the active envelopes.
   */
  set3dEnvelope() {
    this.monkey?.set3dEnvelope();
  }

  // retorna feature de lot y envelope 2d
  getPdfFeature() {
    const pdfFeature = this.monkey.getPdfFeature(this.bbl);
    sessionStorage.setItem('printReportPdfFeature', JSON.stringify(pdfFeature));
    console.log('pdfFeature', pdfFeature);
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }
  /**
   * Method that toggle the camera angle between 2D and 3D.
   * @returns value
   */
  toggle2d() {
    return new Promise<void>((resolve, reject) => {
      this.monkey?.toggle2dCamera();
      resolve();
      //
    });
  }

  /**
   * Method that change the angle to 2D on the map.
   */
  set2dOn() {
    this.monkey?.set2dCamera(true);
  }

  /**
   * Method that turn of the angle 2D on the map
   */
  set2dOff() {
    this.monkey?.set2dCamera(false);
  }

  /**
   * Method that change the state between visible and not visible according to the id.
   * @param layerId layer identifier
   */
  toggleLayer(layerId: string) {
    this.monkey?.toggleLayer(layerId);
  }

  /**
   * Method that change the state to visible according to the id.
   * @param layerId layer identifier
   */
  setLayerOn(layerId: string) {
    this.monkey?.setLayer(layerId, true);
  }

  /**
   * Method that changes the state to not visible according to the id.
   * @param layerId layer identifier
   */
  setLayerOff(layerId: string) {
    this.monkey?.setLayer(layerId, false);
  }

  /**
   * Method that handle the opacity level of the buildings in the map.
   * @param opacity opacity value
   */
  handleSliderOpacity(opacity: number) {
    this.setOpacity3d(opacity);
  }

  /**
   * Method that set opacity 3D in the map.
   * @param opacity opacity value
   */
  setOpacity3d(opacity: number) {
    this.monkey?.setOpacity3d(opacity);
  }

  /**
   * Method that reset the 3D in the map.
   */
  reSet3d() {
    this.monkey?.reSet3dLayer();
  }

  /**
   * Method that go to the bbl
   * @param bbl bbl value
   */

  // jumpToBBL(bbl: number) {
  //   this.backendService.getEnvelope(bbl).subscribe((res: any) => {
  //     this.monkey?.jumpToBBL(res.message['envelope'][0]['sg'][0]);
  //   });
  // }

  /**
   * Method that allow to search an especific location on the map.
   * @param bbl bbl value
   */
  // Metodo para funcionalidad BBL Search
  searchToBBL(data: any) {
    const bbl = data[0];
    const position = data[1];
    this.backendService.getDataFromBBL(bbl,'bbl').subscribe(
      (res: any) => {
        this.monkey?.flyToBBL([res.longitude, res.latitude]);
      },
      (error) => {
        let params: SnackbarTO = {
          message: 'No results found',
          position: position,
          visible: true,
          type: 'error',
        } as SnackbarTO;
        this.paramsSnack = params;
        setTimeout(() => {
          let params: SnackbarTO = {
            message: 'No results found',
            position: position,
            visible: false,
            type: 'error',
          } as SnackbarTO;
          this.paramsSnack = params;
        }, 3000);
      }
    );
  }

  /**
   * Method that show and hide labels, tags.
   */
  setLabelHeightOn() {
    this.monkey?.setLabelHeight(true);
  }

  /**
   * Method that hides labels, tags
   */
  setLabelHeightOff() {
    this.monkey?.setLabelHeight(false);
  }

  /**
   * Method that gets the envelope according the bbl number
   * @param bbl bbl number
   */
  getEnvelopeBBL(bbl: number) {
    this.backendService.getEnvelope(bbl).subscribe((res: any) => {
      this.monkey?.getEnvelopeBBL(res.message, window, Threebox, false);
    });
  }

  /**
   * Method that gets the envelope yard according the bbl number
   * @param bbl bbl number
   */
  getEnvelopeYardBBL(bbl: number) {
    this.backendService.getEnvelope(bbl).subscribe((res: any) => {
      this.monkey?.getEnvelopeBBL(res.message, window, Threebox, true);
    });
  }

  /**
   * Method that enabled the satelite mode on the map.
   */
  showSateliteMode() {
    this.sateliteMode = !this.sateliteMode;
    this.setSatellitemode();
  }

  /**
   * Method that enabled streetview mode on the map.
   */
  showStreetviewMode() {
    if (this.bbl != -1) {
      if (!this.showStreetview) {
        this.openStreetView();
      } else {
        this.closeStreetView();
      }
    }
  }

  /**
   * Method that show buildings on the map.
   */
  showBuildings() {
    this.showBuilding = !this.showBuilding;
    this.onChangeSliderDisabled();
    this.toggle3d();
    console.log(this.enableExtrudeBtn);
  }

  /**
   * Method that toggle the 2D angle on the map.
   */
  setShow2d() {
    this.show2d = !this.show2d;
    this.toggle2d();
  }

  /**
   * Boostrap Modal for export 3dm.
   */
  async openModal(value: any) {
    let modalRef: any;
    switch (value) {
      case 'visualizeExport':
        const exportBBL = await this.monkey.getExportBBL();
        modalRef = this.modalService.open(ViewExportComponent, {
          windowClass: 'modal-viewexport',
        });
        modalRef.componentInstance.bbl = this.bbl;
        modalRef.componentInstance.exportBBL = exportBBL;
        break;

      case 'printReport':
        modalRef = this.modalService.open(PrintReportComponent, {
          windowClass: 'print-report',
        });
        break;
      default:
        break;
    }
  }

  openStreetView() {
    const lotProperties = this.monkey.getLotProperties(this.bbl);

    if (this.streetview) {
      const streetview = document.querySelector(
        '.modal-streetview'
      ) as HTMLElement;
      streetview.classList.remove('hidden-important');

      this.streetview.componentInstance.movePanorama(lotProperties);
    } else {
      this.streetview = this.modalService.open(StreetviewComponent, {
        backdrop: false, // No se cierra al hacer clic fuera
        keyboard: false, // No se cierra con la tecla ESC
        animation: false,
        windowClass: 'modal-streetview',
      });
    }
    this.streetview.componentInstance.lotProperties = lotProperties;
    this.showStreetview = true;
  }

  closeStreetView() {
    if (this.streetview) {
      const streetview = document.querySelector(
        '.modal-streetview'
      ) as HTMLElement;
      streetview.classList.add('hidden-important');
      this.showStreetview = false;
    }
  }
  //modal-use-table
  async openUseTable(selectValue:string) {
    const modalRef = this.modalService.open(UseTableComponent, { 
      windowClass:'modal-use-table', 
      centered:true
    });
    const reduceData = this.useBulkAvailable.map((item:any) => {
      return{
            OVERLAY:item.OVERLAY || false,
            ZONEDIST:item.ZONEDIST || false
          }
    })
    modalRef.componentInstance.overlayZonedistList = reduceData; // Pasar la lista uses al componente
    modalRef.componentInstance.selectedZonedist = selectValue;
  }

  exportPDF = async (bbl?: any) => {
    try {
      const accessToken = this.config.options.accessToken;
      const pdfFeature = this.monkey.getPdfFeature(this.bbl);
      const pdfName = ''  //cambiar valor si viene '' se pondra el valor de la direccion por defecto
      this.loading = true; // Comienza el estado de carga
      await this.pdfReportComponent.generateDataPDF(this.bbl || Number(bbl), accessToken, pdfFeature, pdfName);
    } catch (error) {
      console.error('Error al generar el PDF:', error);
    } finally {
      this.loading = false; // Asegúrate de cambiar el estado de carga al final, sin importar si hubo un error
    }
  };
  /**
   * Select area on map
   */
  setAreaExport(type: number) {
    this.typeAreaExport = type;
    this.monkey.setAreaExport(type);
  }

  /**
   * Method that handles map options angles.
   * @param index identifier value
   */
  handleMapOption(index: number) {
    switch (this.mapOptionList[index].option) {
      case Constants.UP_VIEW:
        this.mapOptionList[index].enabled = !this.mapOptionList[index].enabled;
        this.setSatellitemode();
        this.opacitySliderController = false;
        break;

      case Constants.TWO_DIMENSION:
        this.mapOptionList[index].enabled = !this.mapOptionList[index].enabled;
        if (this.mapOptionList[index].enabled) {
          this.set3dOn();
        } else {
          this.set3dOff();
        }
        break;

      case Constants.THREE_DIMENSION:
        this.mapOptionList[index].enabled = !this.mapOptionList[index].enabled;

        if (this.mapOptionList[index].enabled) {
          this.set2dOn();
          this.opacitySliderController = false;
        } else {
          this.set2dOff();
        }
        break;

      default:
        break;
    }
  }

  /**
   * Method that handles the opacity min value
   * @param event event value
   */
  handleKeyDown(event: any) {
    this.opacityMinValue = Number(event.target.value);
  }

  /**
   * Method that resets the value for the slider.
   */
  resetSlideValue() {
    this.reSet3d();
  }

  /**
   * Method that is always listening clicks events
   * @param event Listener event
   */
  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    event.stopPropagation();
  }

  /**
   * Method that is triggered on change the slider value
   */
  onChangeSliderDisabled(): void {
    this.opacitySliderOptions = Object.assign({}, this.opacitySliderOptions, {
      disabled: !this.showBuilding,
    });
  }
}
