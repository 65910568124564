import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class VisualizePdfFeatureService {
  /**
   * Subject dark mode
   */
  private visualizePdfFeatureSubject = new BehaviorSubject<any>(null as any);

  /**
   * stateObservable
   */
  visualizePdfFeatureData = this.visualizePdfFeatureSubject.asObservable();

  /**
   * Service constructor
   */
  constructor() {}

  /**
   * Method that handles when  a option is tuner off
   */
  getPdfFeatures(): void {
    this.visualizePdfFeatureSubject.next(null as any);
  }
}
