<div class="modal-header">
  <h5 class="modal-title">PDF image preview</h5>
  <p class="middle_subtitle">Go back to map to modify view</p>
  <button type="button" class="close" (click)="closeModal()"></button>
</div>

<div class="modal-body">
  <img id="myImage" class="img_bg" alt="image" />
</div>

<div class="modal-footer">
  <table>
    <tr>
      <td><label for="filename">File name:</label></td>
      <td>
        <input
          type="text"
          id="filename"
          name="filename"
          [(ngModel)]="filename"
        />
      </td>
    </tr>
    <tr>
      <td><label for="filetype">Save as type:</label></td>
      <td>
        <select id="filetype" name="filetype" [(ngModel)]="extension">
          <option
            *ngFor="let filetype of filetypes"
            [value]="filetype.extension"
          >
            {{ filetype.name }}
          </option>
        </select>
      </td>
    </tr>
  </table>
  <!-- <button style="background-color: #f0d459;" type="button" class="btn" (click)="center()">Center</button> -->
  <button
    id="downloadButton"
    type="button"
    class="btn btn-custom"
    (click)="download()"
  >
    Print
  </button>
  <!-- <button type="button" class="btn btn-custom" (click)="closeModal()">Close</button> -->
</div>
