import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, mergeMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CognitoService } from './cognito.service';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  amazonUrl: any;
  getMaintainersGeneralProject(id: number) {
    throw new Error('Method not implemented.');
  }
  /**
   * Header de las consultas.
   */
  private headers: HttpHeaders;

  constructor(
    private cognitoService: CognitoService,
    private http: HttpClient
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  /**
   * Method that gets the selectUse available
   * @param bbl bbl number
   * @returns data from service
   */
  getSelectUseAvailable(bbl: number): Observable<any> {
    return from(this.cognitoService.getRole()).pipe(
      mergeMap((username: string) => {
        return from(this.cognitoService.getToken()).pipe(
          mergeMap((jwt: string) => {
            return this.http.get(`${environment.amazonUrl}usefrombbl?bbl=${bbl}&username=${username}`, {
              headers: new HttpHeaders({
                'Content-type': 'application/json',
                Authorization: `Bearer ${jwt}`,
              }),
            });
          })
        );
      })
    )
  }

  /**
   *Method that gets the envelope according bbl numer
   * @param bbl bbl number
   * @returns data from service
   */
  getEnvelope(bbl: number): Observable<any> {
    return from(this.cognitoService.getToken()).pipe(
      mergeMap((jwt: string) => {
        return this.http.get(`${environment.amazonUrl}envelopes?bbl=${bbl}`, {
          headers: new HttpHeaders({
            'Content-type': 'application/json',
            Authorization: `${jwt}`,
          }),
        });
      })
    );
  }

  getAssemble(jsonData: any): Observable<any> {
    const params = new HttpParams().set('body', JSON.stringify(jsonData));
    return from(this.cognitoService.getRole()).pipe(
      mergeMap((username: string) => {
        return from(this.cognitoService.getToken()).pipe(
          mergeMap((jwt: string) => {
            return this.http.get(`${environment.amazonUrl}assemble?username=${username}`, {
              params: params,
              headers: new HttpHeaders({
                'Content-type': 'application/json',
                Authorization: `${jwt}`,
              }),
            });
          })
        );
      })
    )
  }

  getDataFromBBL(bbl: number, tag:string = 'unknown'): Observable<any> {
    return from(this.cognitoService.getRole()).pipe(
      mergeMap((username: string) => {
        return from(this.cognitoService.getToken()).pipe(
          mergeMap((jwt: string) => {
            return this.http.get(`${environment.amazonUrl}lotfrombbl?bbl=${bbl}&username=${username}&tag=${tag}`, {
              headers: new HttpHeaders({
                'Content-type': 'application/json',
                Authorization: `Bearer ${jwt}`,
              }),
            });
          })
        );
      })
    )
  }

  getDataFromRegulations(bbl: number): Observable<any> {
    return from(this.cognitoService.getRole()).pipe(
      mergeMap((username: string) => {
        return from(this.cognitoService.getToken()).pipe(
          mergeMap((jwt: string) => {
            return this.http.get(`${environment.amazonUrl}regulations?bbl=${bbl}&username=${username}`, {
              headers: new HttpHeaders({
                'Content-type': 'application/json',
                Authorization: `Bearer ${jwt}`,
              }),
            });
          })
        );
      })
    )
  }
}
