<section
  class="row nav_bar"
  [ngClass]="{ 'nav_bar--light': themeMode === 'light' }"
>
  <article class="nav_bar_cnt">
    <h1 class="logo_text">zlvas</h1>
    <section class="options">
      <article class="content">
        <ul class="options_cnt">
          <li
            class="item"
            *ngFor="let item of searchOptions; let i = index"
            [style.pointer-events]="!item.clickable ? 'none' : 'all'"
            (click)="handleOptionSelected(searchOptions, i, item.optionNumber)"
          >
            <span
              class="{{ item.icon }} icon"
              [ngClass]="{ 'icon--active': !!item.enabled }"
              [style.opacity]="!item.clickable ? 0.5 : 1"
            >
            </span>
            <article class="info_cnt">
              <div
                class="tooltip"
                [ngStyle]="{
                  '--percentage': !!item.percentage ? item.percentage : 0
                }"
              >
                {{ item.name }}
              </div>
            </article>
          </li>
        </ul>

        <div class="separator"></div>

        <ul class="options_cnt mt-4">
          <li
            class="item"
            *ngFor="let item of zoningOptions; let x = index"
            [style.pointer-events]="!item.clickable ? 'none' : 'all'"
            (click)="handleOptionSelected(zoningOptions, x, item.optionNumber)"
          >
            <span
              class="{{ item.icon }} icon"
              [ngClass]="{ 'icon--active': !!item.enabled }"
              [style.opacity]="!item.clickable ? 0.5 : 1"
            ></span>
            <article class="info_cnt" (click)="childClick($event)">
              <div
                class="tooltip"
                [ngStyle]="{
                  '--percentage': !!item.percentage ? item.percentage : 0
                }"
              >
                {{ item.name }}
                <span class="identifier">{{ item.identificatorName }}</span>
              </div>
            </article>
          </li>
        </ul>
      </article>

      <ul class="options_cnt">
        <div class="top_separator"></div>
        <li
          class="item"
          *ngFor="let item of generalOptions; let y = index"
          [style.pointer-events]="!item.clickable ? 'none' : 'all'"
          [ngClass]="{ 'item--padding': item.topSeparator }"
          (click)="handleGeneralOptionSelected(generalOptions, y)"
        >
          <span
            class="{{ item.icon }} icon"
            [ngClass]="{ 'icon--active': !!item.enabled }"
            [style.opacity]="!item.clickable ? 0.5 : 1"
          ></span>
          <article class="info_cnt">
            <div
              class="tooltip"
              [ngStyle]="{
                '--percentage': !!item.percentage ? item.percentage : 0
              }"
            >
              {{ item.name }}
            </div>
          </article>
        </li>
      </ul>
    </section>
  </article>
</section>

<section class="extent_option" *ngIf="showExtentOptions">
  <section
    class="extent_option_cnt"
    [ngClass]="{
      'extent_option_cnt--print': !!printReportOpened && !visualizeOpened
    }"
  >
    <div class="header_info d-flex justify-content-between">
      <p class="header_text">
        {{
          !!visualizeOpened && !printReportOpened
            ? "Select Export Extent"
            : !!printReportOpened && !visualizeOpened
            ? "Print Report"
            : ""
        }}
      </p>
      <span class="icon-left-arrow icon" (click)="close()"></span>
    </div>
    <section class="body_info" *ngIf="visualizeOpened">
      <div class="input_element">
        <app-radio
          (onClickRadio)="handlererRadio($event)"
          [radioList]="radioExtentList"
          [form]="form"
        ></app-radio>
      </div>

      <div class="btn_cnt">
        <button
          class="btn_visualize"
          (click)="setVisualization('visualizeExport')"
          [disabled]="!enableVisualize"
        >
          Visualize
        </button>
      </div>
    </section>

    <section class="body_info" *ngIf="printReportOpened">
      <div class="btn_cnt">
        <button
          class="btn_visualize"
          (click)="setVisualization('printReport')"
          [disabled]="!enableVisualizePrintReport"
        >
          Visualize
        </button>
      </div>
    </section>
  </section>
</section>
