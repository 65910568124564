import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalViewService {
  /**
   * Subject dark mode
   */
  private modalViewSubject = new BehaviorSubject<any>(null as any);

  /**
   * stateObservable
   */
  modalViewData = this.modalViewSubject.asObservable();

  /**
   * Service constructor
   */
  constructor() {}

  /**
   * Method that handles when  a option is tuner off
   */
  handleOpenModalView(value: any): void {
    this.modalViewSubject.next(value as any);
  }
}
