import { style } from '@angular/animations';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PrintService } from 'src/app/services/print.service';
import { VisualizePdfFeatureService } from 'src/app/services/visualize-pdf-feature.service';

@Component({
  selector: 'app-print-report',
  templateUrl: './print-report.component.html',
  styleUrls: ['./print-report.component.scss'],
})
export class PrintReportComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    public visualizePdfFeature: VisualizePdfFeatureService,
    public printService: PrintService
  ) {}

  bbl: any;
  filename: any;
  extension: any;
  filetypes: any[] | undefined;
  pdfFeatureData: any;
  imgElement: any;

  async ngOnInit() {
    this.bbl = sessionStorage.getItem('bbl');
    this.filetypes = [{ name: 'pdf', extension: 'pdf' }];
    this.extension = this.filetypes[0].extension;
    this.getPdfFeature();
  }

  async getPdfFeature() {
    const imgValue = document.getElementById('myImage') as HTMLImageElement;
    this.visualizePdfFeature.getPdfFeatures();
    this.pdfFeatureData = sessionStorage.getItem('printReportPdfFeature');
    this.pdfFeatureData = JSON.parse(this.pdfFeatureData);

    const dimensions:any = await this.getImageDimensions(this.pdfFeatureData.imageUrl);
    imgValue.src = this.pdfFeatureData.imageUrl;
    imgValue.style.width = `${dimensions.width - dimensions.width*0.50}px`;
    imgValue.style.height = `${dimensions.height - dimensions.height*0.50}px`;
    this.imgElement = imgValue;
    
    this.filename = this.pdfFeatureData.address;
    console.log('test', this.pdfFeatureData);
  }

  async getImageDimensions(base64Image: string) {
    const img = new Image();
  
    return new Promise((resolve, reject) => {
      img.onload = function() {
        resolve({ width: img.width, height: img.height });
      };
      
      img.onerror = function() {
        reject('Error al cargar la imagen');
      };
      
      img.src = base64Image;
    });
  }
  
  
  download() {
    this.printService.setPrintExport(this.bbl);
  }

  closeModal() {
    this.activeModal.close();
  }
}
