import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepicker, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccordionComponent } from './accordion/accordion.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { InputComponent } from './input/input.component';
import { MapComponent } from './map/map.component';
import { SelectComponent } from './select/select.component';
import { SwitchComponent } from './switch/switch.component';
import { RadioComponent } from './radio/radio.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LoadingComponent } from './loading/loading.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ViewExportComponent } from './view-export/view-export.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { PdfReportComponent } from './pdf-report/pdf-report.component';
import { StreetviewComponent } from './streetview/streetview.component';
import { UseTableComponent } from './use-table/use-table.component';
import { PrintReportComponent } from './print-report/print-report.component';

@NgModule({
  declarations: [
    NavBarComponent,
    InputComponent,
    MapComponent,
    AccordionComponent,
    CheckboxComponent,
    SelectComponent,
    SwitchComponent,
    RadioComponent,
    LoadingComponent,
    ViewExportComponent,
    SnackbarComponent,
    PdfReportComponent,
    StreetviewComponent,
    UseTableComponent,
    PrintReportComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgxSliderModule,
  ],
  exports: [
    NavBarComponent,
    InputComponent,
    MapComponent,
    AccordionComponent,
    CheckboxComponent,
    SelectComponent,
    SwitchComponent,
    RadioComponent,
    LoadingComponent,
    PdfReportComponent,
  ],
  providers: [NgbDatepicker],
})
export class UiModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {
    return {
      ngModule: UiModule,
      providers: [
        {
          provide: 'env',
          useValue: environment,
        },
      ],
    };
  }
}
