import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


type URLData = { [section: string]: string }; // Definición del tipo
@Component({
  selector: 'app-use-table',
  templateUrl: './use-table.component.html',
  styleUrls: ['./use-table.component.scss']
})
export class UseTableComponent implements OnInit {
  
  ID_INDEX: number = 0;
  csvData: string[][] = [];
  headers: string[] = [];
  newCsvData: string[][] = [];  
  overlayZonedistList: { OVERLAY: string, ZONEDIST: string }[] = [];  
  columnsToCopy: string[] = ['ID', 'Use'];  
  newHeaders: string[] = []
  selectedZonedist: string = ''
  urlData: URLData = {};
  

  public activeModal!: NgbActiveModal;
  constructor(private http: HttpClient,activeModal: NgbActiveModal) {
    this.activeModal = activeModal;
  }

  ngOnInit(): void {
    this.loadCSV();
    this.loadUrlData()
  }

  loadCSV(): void {
    this.http.get('assets/COY_Use_MASTER.csv', { responseType: 'text' }).subscribe(
      (data) => {
        this.parseCSV(data);
      },
      (error) => {
        console.error('Error loading the CSV file', error);
      }
    );
  }
  closeModal() {
    this.activeModal.close(); 
  }

  loadUrlData(): void {
    this.http.get('assets/COY_Dictionary.csv', { responseType: 'text' }).subscribe(
      (data) => {
        this.urlData = this.processCsvData(data);
        console.log(this.urlData); // Ahora puedes usar el CSV procesado
      },
      (error) => {
        console.error('Error al cargar el archivo CSV', error);
      }
    );
  }
  
  processCsvData(csvText: string): { [section: string]: string } {
    const rows = csvText.split('\n');
    const result: { [section: string]: string } = {};
  
    rows.slice(1).forEach(row => {
      const columns = row.split(';');
  
      // Verificar que hay al menos dos columnas antes de procesar
      if (columns.length >= 2) {
        const section = columns[0].trim();
        const url = columns[1].trim();
        result[section] = url;
      }
    });
  
    return result;
  }

  parseCSV(csv: string): void {
    const lines = csv.split('\n');
    this.csvData = lines.map(line => line.split(';'));
    this.headers = this.csvData[0];  

    this.applyOverlayZonedistLogic();
  }
  
  applyOverlayZonedistLogic(): void {
    // Recorrer overlayZonedistList y aplicar la lógica
    this.overlayZonedistList.forEach(obj => {
      const overlayValue = obj.OVERLAY;
      const zonedistValue = obj.ZONEDIST;

      if (overlayValue && overlayValue !== 'false') {  // Si OVERLAY no es 'false'
        const columnPrefix = overlayValue.slice(0, 2).toUpperCase();  // Obtener los dos primeros caracteres y convertir a mayúscula
        if(this.headers.includes(columnPrefix)){
          this.newHeaders.push(`${zonedistValue}/${overlayValue}`)
          this.columnsToCopy.push(columnPrefix);  // Agregar la columna con el prefijo a la lista
        }
        
      } else if (zonedistValue) {  // Si OVERLAY es false, usar ZONEDIST
        const uniqueValues = [
          'R10H',	'R11',	'R12','M1-1D',	'M1-2D',	'M1-3D',	'M1-4D',	'M1-5D',	'M1-6D',
        ]
        if(!uniqueValues.includes(zonedistValue)){
          if (zonedistValue.includes('/')) { 
            this.newHeaders.push(`${zonedistValue}`)
  
            this.columnsToCopy.push('M/R');  // Agregar 'M/R' si ZONEDIST contiene '/'
          } else {
            const zonedistPrefix = zonedistValue.slice(0, 2).toUpperCase();  // Obtener prefijo de ZONEDIST
            if (this.headers.includes(zonedistPrefix)){
              this.newHeaders.push(`${zonedistValue}`)
  
              this.columnsToCopy.push(zonedistPrefix);  
            }else{
              const zonedistPrefix = zonedistValue.slice(0, 3).toUpperCase();  // Obtener prefijo de ZONEDIST
              if (this.headers.includes(zonedistPrefix)){
                this.newHeaders.push(`${zonedistValue}`)
                this.columnsToCopy.push(zonedistPrefix);  
              }
            }
          }
        }else{
          this.newHeaders.push(`${zonedistValue}`)
          this.columnsToCopy.push(zonedistValue)
        }
        
      }
    });

    // Actualizar el CSV después de aplicar la lógica
    this.updateCsvData();
  }
  
  updateCsvData(): void {
    const newHeaders = this.columnsToCopy; 
    const newCsv: string[][] = [newHeaders];  

    // Recorrer cada fila del CSV original y copiar las columnas seleccionadas
    this.csvData.slice(1).forEach(row => {
      const newRow = this.columnsToCopy.map(columnName => {
        const columnIndex = this.headers.indexOf(columnName);
        return columnIndex !== -1 ? row[columnIndex] : ''; 
      });
      newCsv.push(newRow);
    });
    this.newHeaders.unshift('ID', 'Use')
    this.newCsvData = newCsv;  
  }
  
}
