export const environment = {
  testPanel: false,
  amazonUrl: 'https://api.zlvas.com/',
  mapbox: {
    accessToken:
      'pk.eyJ1IjoibWFuaWF0aWNjIiwiYSI6ImNsY2drbDF5bTNrYjYzcG1vbjVmN3oxMzUifQ.u_gZ4dcArz4CaxP0Dc6bZQ',
  },
  cognito: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_vQ4qbcef5',
    userPoolWebClientId: '16kh5ukphmk8b1itjgibu9dvst',
  },
};
